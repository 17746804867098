/**
 * @description This file is for api hitting using
 * Gateway for the create Admin web UI user.
 */
import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { getUserMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description Function to logout webUI user
 * @memberof loginModule
 */

export const getUserMe = async (): Promise<any | IErrorResponse> => {
  try {
    const url = getUserMgmtEndpoint(`/users/me`);
    return Gateway.get(url, null, null);
  } catch (error) {
    return { error };
  }
};
