// @ts-ignore
import { MasterActions } from "custom-actions-admin";
import { put, takeLatest } from "redux-saga/effects";
import { getLanguage, getTimezone } from "../../api/masterDataApi";
import { call } from "../common/commonSaga";

export function* timezoneListSaga() {
  yield takeLatest(MasterActions.GET_TIMEZONE_LIST_SUCCESS, fetchTimezoneList);
}
export function* fetchTimezoneList() {
  const response = yield call(() => {
    return getTimezone();
  });
  if (response && !response.error) {
    yield put({
      payload: response,
      type: MasterActions.PUT_TIMEZONE_LIST,
    });
  }
}

/**
 * Saga to fetch all languages list.
 */
export function* getLanguageListSaga() {
  yield takeLatest(MasterActions.OM_GET_LANGUAGE_LIST, getLanguageList);
}

export function* getLanguageList() {
  let response = yield call(() => {
    return getLanguage();
  });
  if (response && response.error) {
    response = [];
  }
  yield put({ type: MasterActions.OM_LANGUAGE_RESPONSE, payload: response });
}
