/**
 * @description Action constants to be used across the project in admin-ui when Promoting a Tenant User
 *
 * format: {feature}_{action}
 */

const UserProfileConstants = {
  GET_USER_ME: "GET_USER_ME",
  PUT_USER_ME_SUCCESS: "PUT_USER_ME_SUCCESS",
};

export default UserProfileConstants;
