import React, { FC } from 'react';
import type { ChipProps } from '@mui/material/Chip';

import BadgeList from './BadgeList';
import { ClearIcon } from '../icons';
import { StyledChip } from './Badge.styled';

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    default: true;
    primary: false;
    secondary: false;
    error: true;
    info: true;
    success: true;
    warning: true;
  }
}

export type BadgeProps = Omit<ChipProps, 'children'>;

const defaultProps: Partial<BadgeProps> = {
  color: 'default',
  deleteIcon: <ClearIcon />,
  className: '',
};

const Badge: FC<BadgeProps> = (props: BadgeProps) => <StyledChip {...props} />;

Badge.defaultProps = defaultProps;

type BadgeComponent<P = BadgeProps> = FC<P> & {
  List: typeof BadgeList;
};

export default Badge as BadgeComponent;
