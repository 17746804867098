/**
 * @description this file lists all the configuration related to hilti admin portal.
 * this includes routing.
 *
 * Routing:
 * 1. / considered to be the root or home
 * 2. /tenant considers /tenant and it's subroutes
 */
import React from "react";
import LazyLoadModule from "./common/lazyLoadModule";

export const publicRoutes = [
  {
    component: (props) => (
      <LazyLoadModule {...props} resolve={() => import(/*webpackChunkName: "login"*/ "./common/modules/login")} />
    ),
    path: "/login",
  },
  {
    component: (props) => (
      <LazyLoadModule
        {...props}
        resolve={() => import(/*webpackChunkName: "errorModule"*/ "./common/modules/errorModule")}
      />
    ),
    path: "/error/:errCode",
  },
  {
    component: (props) => (
      <LazyLoadModule
        {...props}
        resolve={() => import(/*webpackChunkName: "errorModule"*/ "./common/modules/errorModule")}
      />
    ),
    path: "/error",
  },
  {
    component: (props) => <LazyLoadModule {...props} resolve={() => import("./common/modules/customerAuthCallback")} />,
    exact: false,
    path: "/customer/oauth_redirect",
    noHeader: true,
  },
  {
    component: (props) => <LazyLoadModule {...props} resolve={() => import("./common/modules/customerAuth")} />,
    exact: false,
    path: "/customer/oauth",
    noHeader: true,
  },
];

export const protectedRoutes = [
  {
    component: (props) => (
      <LazyLoadModule {...props} resolve={() => import(/*webpackChunkName: "home"*/ "./common/modules/tenant")} />
    ),
    exact: true,
    path: "/",
  },
  {
    component: (props) => (
      <LazyLoadModule {...props} resolve={() => import(/*webpackChunkName: "tenant"*/ "./common/modules/tenant")} />
    ),
    exact: false,
    path: "/customers",
  },
  {
    component: (props) => (
      <LazyLoadModule {...props} resolve={() => import(/*webpackChunkName: "user"*/ "./common/modules/adminUser")} />
    ),
    exact: false,
    path: "/users",
    dependencies: {
      access: "ADMIN_PORTAL",
    },
  },
];
