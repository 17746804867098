// @ts-ignore
import { LoginActions } from "custom-actions-admin";
import { call, put, takeLatest } from "redux-saga/effects";
import { getMarketOrganization } from "../../api/loginApi";

export function* loginMarketOrgSaga() {
  yield takeLatest(LoginActions.GET_LOGIN_MARKET_ORGANIZATIONS, fetchLoginMarketOrgList);
}

function* fetchLoginMarketOrgList() {
  const marketOrg = yield call(getMarketOrganization);
  if (!marketOrg.error) {
    yield put({ type: LoginActions.GET_MARKET_ORGANIZATIONS_SUCCESS, payload: marketOrg });
  }
}
