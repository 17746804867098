const StyleAttrConstants = {
  LEFT: {
    L176: 176,
  },
  OPACITY: {
    OP3: 0.3,
    OP5: 0.5,
  },
  TOP: {
    T48: 48,
    T72: 72,
  },
  WIDTH: {
    W200: 200,
  },
};
export default StyleAttrConstants;
