import { DefaultCrypto } from "@openid/appauth";

export const getRandomVerifier = (length: number): string => {
  const zeroLength = 0;
  if (length <= zeroLength) {
    return "";
  }

  const crypto = new DefaultCrypto();
  return crypto.generateRandom(length);
};

export const getCodeChallenge = async (): Promise<string> => {
  const RANDOM_VERIFIER_LENGTH = 128;
  const crypto = new DefaultCrypto();
  return crypto.deriveChallenge(getRandomVerifier(RANDOM_VERIFIER_LENGTH));
};
