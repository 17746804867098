// @ts-ignore
import { ToastrActions } from "custom-actions-admin";

/**
 * @description - Types of the toastr messages that can be shown to the user.
 */
export enum ToastrType {
  warning = "Warning",
  error = "error",
  info = "info",
  success = "success",
}

/**
 * @description - This method is used to trigger the action to show the toastr message.
 *
 * @param type - This is the type of toastr needs to be shown.
 * @param toastrMessage - This is the message that needs to be shown on the toastr.
 */
export const showToastr = (type: ToastrType, toastrMessage, translate?) => ({
  payload: { toastrType: type, toastrMessage, translate },
  type: ToastrActions.SHOW_TOASTR,
});

/**
 * @description - This method is used to trigger the success action of the toastr.
 *
 * @param toastrMessage - This is the message that needs to be shown on the toastr.
 */
export const showSuccessToastr = (toastrMessage) => ({
  payload: { toastrType: ToastrType.success, toastrMessage },
  type: ToastrActions.SHOW_TOASTR,
});

/**
 * @description - This method is used to trigger the close action of the toastr.
 */
export const closeToastr = () => ({
  type: ToastrActions.CLOSE_TOASTR,
});
