/**
 * Define possible role for user in system
 */
const UserRoles = {
  ADMIN: "ADMIN",
  SUPPORT_ADMIN: "SUPPORT_ADMIN",
  TECHNICAL_USER: "TECHNICAL_USER",
  TECHNICAL_USER_LOCKER: "TECHNICAL_USER_LOCKER",
};

/** export user roles */
export default UserRoles;
