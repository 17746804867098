/**
 * @description Action constants to be used across the project in admin-ui when Promoting a Tenant User
 *
 * format: {feature}_{action}
 */
const UserActionConstants = {
  CLEAR_UPDATE_USER_ROLE: "CLEAR_UPDATE_USER_ROLE",
  PUT_USER_BYEMAIL_FAIL: "PUT_USER_BYEMAIL_FAIL",
  PUT_USER_BYEMAIL_SUCCESS: "PUT_USER_BYEMAIL_SUCCESS",
  UM_GET_USER_BYEMAIL: "UM_GET_USER_BYEMAIL",
  UM_UPDATE_USER_ROLE: "UM_UPDATE_USER_ROLE",
  UPDATE_USER_ROLE_FAIL: "UPDATE_USER_ROLE_FAIL",
  UPDATE_USER_ROLE_SUCCESS: "UPDATE_USER_ROLE_SUCCESS",
};

export default UserActionConstants;
