import {
  AppAuthError,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  TokenError,
  TokenErrorJson,
  TokenRequest,
} from "@openid/appauth";
import HiltiToken, { HiltiTokenJson } from "./hiltiToken";

const isHiltiTokenResponse = (response: HiltiTokenJson | TokenErrorJson): boolean =>
  (response as TokenErrorJson).error === undefined;

class HiltiTokenRequestHandler extends BaseTokenRequestHandler {
  performHiltiTokenRequest(
    configuration: AuthorizationServiceConfiguration,
    request: TokenRequest,
  ): Promise<HiltiToken> {
    const tokenResponse = this.requestor.xhr<HiltiTokenJson | TokenErrorJson>({
      url: configuration.tokenEndpoint,
      method: "POST",
      dataType: "json",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: this.utils.stringify(request.toStringMap()),
    });

    return tokenResponse.then((response) => {
      if (isHiltiTokenResponse(response)) {
        return new HiltiToken(response as HiltiTokenJson);
      }

      const errorJson = response as TokenErrorJson;
      return Promise.reject<HiltiToken>(new AppAuthError(errorJson.error, new TokenError(errorJson)));
    });
  }
}

export default HiltiTokenRequestHandler;
