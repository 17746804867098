/**
 * @description Action constants to be used across the project
 *
 * format: {feature}_{action}
 */
const ToastrActions = {
  // action to get Toastr
  CLOSE_TOASTR: "CLOSE_TOASTR",
  SHOW_TOASTR: "SHOW_TOASTR",
};

export default ToastrActions;
