// @ts-ignore
import { AsyncExportActions } from "custom-actions-admin";
// @ts-ignore
import { HelperLodash } from "am-web-ui-shared/helpers";

const pollingActionConstants = {
  user: AsyncExportActions.EXPORT_TIMESTAMPED_USER_DATA_POLLING,
};

export const getExportTimestampedUserData = () => ({
  type: AsyncExportActions.EXPORT_TIMESTAMPED_USER_DATA,
});

export const pollingStart = (moduleName: string) => ({
  type: HelperLodash.get(pollingActionConstants, moduleName),
});
