import { HelperLodash } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../applicationConstants";
import ErrorHandler from "../models/errorModel";
import { AllowedIps, ErrorType, FieldNameBadgeId } from "../models/errorType";
import { setScrollOnField } from "./commonUtils";
import { ValidationConstants } from "./enums/validationConstants";

const pleaseEnter = "common:VALIDATION_PLEASE_ENTER";
const charValidationMessage = "common:VALIDATION_CHARACTERS_ALLOWED";
const pleaseProvideValid = "common:VALIDATION_PLEASE_PROVIDE_VALID";
const pleaseSelectMessage = "common:VALIDATION_PLEASE_SELECT";
export const required =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    return value && allValues && props ? undefined : t(pleaseEnter) + (fieldName ? t(fieldName) : name);
  };

export interface IFieldForValidation {
  dependentKeys?: string[];
  key: string;
  name: string;
  isRequired: boolean;
  regex?: RegExp;
  isValid?: boolean;
}

export const locales = {
  charAllowed: charValidationMessage,
  charRequired: "common:VALIDATION_CHARACTERS_REQUIRED",
  countryCode: "common:VALIDATION_CHARACTERS_ALLOWED_COUNTRY_CODE",
  maxOf: "common:VALIDATION_MAXIMUM_OF",
  minOf: "common:VALIDATION_MIN_OF",
  pleaseEnter,
  pleaseInter: pleaseEnter,
  pleaseProvide: "common:VALIDATION_PLEASE_PROVIDE",
  pleaseProvideValid: "common:VALIDATION_PLEASE_PROVIDE_VALID",
  pleaseSelect: pleaseSelectMessage,
  unhandled: "common:UNHANDLED_EXCEPTION",
  vaildId: "common:VALIDATION_PLEASE_ENTER_VALID_VALUE",
};

export const selectRequired =
  (t, fieldName = null) =>
  (value, allValues, props, name) => {
    return value && allValues && props ? undefined : `${t(pleaseSelectMessage)}${fieldName ? t(fieldName) : name}`;
  };

/**
 * @description function check Creatable dropdown is required
 * @param t
 * @param fieldId creatable dropDown contain two field which field set to required param decide
 * @param fieldName fieldname is resolved name after translator
 */
export const selectCreatableRequired =
  (t, fieldId, fieldName = null) =>
  (value, allValues, props, name) => {
    return value || (HelperLodash.get(allValues, fieldId) && props)
      ? undefined
      : `${t(pleaseSelectMessage)}${fieldName ? t(fieldName) : name}`;
  };

export const customRequired = (t, message) => (value, allValues, props) => {
  return value && allValues && props ? undefined : t(message);
};

export const maxLength = (t, max) => (value) => {
  return max > -1 && value && value.length > max ? `${t(locales.maxOf)} ${max} ${t(charValidationMessage)}` : undefined;
};

export const minLength = (t, min) => (value) => {
  return min > -1 && value && value.length < min ? `${t(locales.minOf)} ${min} ${t(locales.charRequired)}` : undefined;
};

export const originCustomerIdValidation = (t, marketOrg) => (value) => {
  const isS4MarketOrg = marketOrg && ApplicationConstants.S4_CUSTOMERS_MARKET_ORG_LIST.includes(marketOrg);
  if (isS4MarketOrg) {
    const min = ValidationConstants.MAX_LENGTH.NUM_10;
    return value?.length !== min ? `${t("common:VALIDATION_ONLY_ALLOWED_LENGTH")} ${min}` : undefined;
  }

  return maxLength(t, ValidationConstants.MAX_LENGTH.NUM_36)(value);
};

/**
 * @description  function throw an error when compare field length with specified value.
 * @param {Array} - fieldArray - Contains array of field attributes.
 */
export const maxLengthFieldSet = (t, fieldArray) => (value, allValues, props, name) => {
  const fieldName = HelperLodash.get(fieldArray, name);
  return Object.keys(fieldArray).indexOf(name) > -1 && value && value.length > fieldName && allValues && props && name
    ? `${t(locales.maxOf)} ${fieldName} ${t(charValidationMessage)}`
    : undefined;
};

/**
 * @description  function throw an error when field1 is available but field2 is not available or vice versa.
 * @param {field1} - First Field.
 * @param {field2} - Second Field.
 * @param {field1Name} - field1Name is used to show error for field1.
 * @param {field2Name} - field2Name is used to show error for field2.
 * @param {isRequired} - isRequired is used to indicate whether both fields are required or not, if required we are handling required validation also.
 */
export const validateAssociatedFields =
  (t, firstField, secondField, firstFieldName, secondFieldName, isRequired) => (_value, allValues) => {
    const val1 = HelperLodash.get(allValues, firstField);
    const val2 = HelperLodash.get(allValues, secondField);
    if ((val1 && val2) || (!val1 && !val2 && !isRequired)) {
      return undefined;
    } else if (!val1 && !val2 && isRequired) {
      return `${t(pleaseProvideValid)} ${firstFieldName} & ${secondFieldName}`;
    } else {
      return `${t(pleaseProvideValid)} ${!val1 ? firstFieldName : secondFieldName}`;
    }
  };

const validateField = (isRequired, value, regex) => {
  let isValid = false;
  if (isRequired) {
    isValid = regex ? !!value && regex.test(value) : !!value;
  } else {
    isValid = !!value && regex ? regex.test(value) : true;
  }
  return isValid;
};

const getIsRequiredForDependentField = (field, allValues) => {
  if (field.dependentKeys) {
    const dependentValues = field.dependentKeys.map((key) => {
      return !!HelperLodash.get(allValues, key);
    });
    return field.isRequired || dependentValues.includes(true);
  }
  return field.isRequired;
};

export const modifiedValidateAssociatedFields = (t, fields: IFieldForValidation[]) => (_value, allValues) => {
  const fieldsInfo = fields.map((field) => {
    const value = HelperLodash.get(allValues, field.key);
    return {
      ...field,
      isValid: validateField(getIsRequiredForDependentField(field, allValues), value, field.regex),
      value,
    };
  });

  const fieldsWithError = fieldsInfo.filter((field) => field.isValid === false);
  if (fieldsWithError.length === 0) {
    return undefined;
  }
  return `${t(locales.pleaseProvideValid)} ${t("common:COUNTRY_CODE_AND_MOBILE")}`;
};

export const regexValidation = (regex, message) => (value) => {
  return value ? (!regex.test(value) ? message : undefined) : null;
};

export const onlySpaceNotAllowed = (t) => (value) => {
  const regexSpace = /\s/g;

  return value && value.match(regexSpace) && value.trim() === ""
    ? t("common:VALIDATION_ONLY_SPACE_NOT_ALLOWED")
    : undefined;
};

/**
 * @@description function resolved error from field and return a error object
 * @param error collection of errors
 */
export const errorResolver = (error) => {
  const errorCollection = Array.isArray(error) ? error : [error];
  const errorObject = new ErrorHandler();
  errorCollection.forEach((item) => {
    if (item && item.fieldName && item.fieldName !== "N/A") {
      const fieldNames = item.fieldName.split("/");
      if (fieldNames.length > 1 && fieldNames[fieldNames.length - 1] === FieldNameBadgeId) {
        errorObject.add({
          errorType: ErrorType.SingleField,
          ...item,
          fieldName: fieldNames[fieldNames.length - ApplicationConstants.LENGTH.LEN2],
        });
      }
      if (item.fieldName.substr(0, AllowedIps.length).includes(AllowedIps)) {
        errorObject.add({ errorType: ErrorType.SingleField, ...item, fieldName: AllowedIps });
      } else {
        errorObject.add({ errorType: ErrorType.SingleField, ...item, fieldName: item.fieldName.split("/").pop() });
      }
    } else {
      errorObject.add({ errorType: ErrorType.UnhandledException, ...item });
    }
  });
  return errorObject;
};

/**
 * @description Inject error to the field
 * @param error error collection come from APi
 * @param props props contain redux-form state
 * @param callBack callback is used to handle unExecptional error
 */
export const injectError = (error, props, t, callBack = null) => {
  if (error.response && error.response.data) {
    const errorObject = errorResolver(error.response.data.errors);
    setTimeout(() => {
      const errorField = errorObject.getNormalizedSubmitError(
        [ErrorType.SingleField],
        props.registeredFields,
        t,
        props.values,
      );
      setScrollOnField();
      props.stopSubmit(errorField);
    }, ApplicationConstants.TIMEOUT.TOUT200);

    if (callBack) {
      callBack(
        errorObject.getErrorMessage([ErrorType.UnhandledException, ErrorType.CombinationField], t, props.values),
      );
    }
  }
};

// @ts-ignore
export const validateNewOptions = (range) => (inputValue, selectValue, selectOptions) => {
  /**
   * range: given range of input
   * inputValue: The value which is being entered in the input box
   * selectValue: The selected value from the options
   * selectOptions: The filtered options
   */
  let inputExist = false;
  selectOptions.filter((ele: any) => {
    if (ele.label.toLowerCase() === inputValue.trim().toLowerCase()) {
      inputExist = true;
    }
  });
  return inputValue && inputValue.trim().length > 0 && inputValue.length <= range && !inputExist;
};

/**
 * @description - Function to validate response having errors
 *
 * @param response - response from API
 */
export const validateResponse = (response) => {
  return (((((response || {}).error || {}).response || {}).data || {}).errors || [])[0];
};
