import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas";
import commonRootReducer from "./../reducers/index";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const sagaMiddleware = createSagaMiddleware();

/**
 * @description Configures the redux store.
 * Store is created with root reducer along with the root Saga for Redux-Saga.
 *
 * @returns the store object which is used by the provider.
 */
function configureStore() {
  // __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ is available if extension is present
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const appStore: any = createStore(
    commonRootReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(sagaMiddleware)),
  );
  appStore.runSagaTask = () => {
    appStore.sagaTask = sagaMiddleware.run(rootSaga);
  };
  appStore.runSagaTask();
  return appStore;
}

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
