import Gateway from "../common/gateway/gateway";

import { getFeatureFlagsEndpoint } from "../common/utils/apiUtils";

export const getfeatureFlagsData = async (product, userId): Promise<any> => {
  return Gateway.get(
    getFeatureFlagsEndpoint(`/products/${product}/feature-flags?userId=${userId}`),
    null,
    null,
    null,
  ) as unknown as any;
};
