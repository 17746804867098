// @ts-ignore
import { AdminUsersActionConstants } from "custom-actions-admin";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { addAdminUserApi, adminUsersApi, deleteAdminUserApi } from "../../api/adminUsersApi";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import { validateResponse } from "../../utils/errorUtils";
import { getQueryObj } from "../stateSelector";

/**
 * Saga for adding new admin user
 */
export function* addAdminUserSaga() {
  yield takeLatest(AdminUsersActionConstants.ADD_ADMIN_USER, addAdminUser);
}

export function* addAdminUser(action) {
  const response = yield call(addAdminUserApi, action.payload);
  if (response.error) {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, true));
    }
  } else {
    yield put({ payload: response, type: AdminUsersActionConstants.ADD_ADMIN_USER_SUCCESS });
    yield put(showToastr(ToastrType.success, "common:ADDED_SUCCESSFULLY", true));
    yield put({ offset: 0, type: AdminUsersActionConstants.FETCH_ADMIN_USERS });
  }
}

/**
 * Saga for fetching admin user list
 */

export function* fetchAdminUsersSaga() {
  yield takeLatest(AdminUsersActionConstants.FETCH_ADMIN_USERS, fetchAdminUsers);
}

export function* fetchAdminUsers(action) {
  if (!action.offset) {
    yield put({ type: AdminUsersActionConstants.ADMIN_USERS_LOADER_START });
  }
  const querysObj = yield select(getQueryObj, action.offset, "adminUsers");
  let response = yield call(adminUsersApi, querysObj);
  if (response.error || !response.response) {
    response = { response: [], totalRecords: 0, offset: action.offset, limit: action.limit };
  }
  yield put({
    offset: action.offset,
    payload: response,
    type: AdminUsersActionConstants.FETCH_ADMIN_USERS_SUCCESS,
  });
  if (!action.offset) {
    yield put({ type: AdminUsersActionConstants.ADMIN_USERS_LOADER_END });
  }
}

export function* deleteAdminUserSaga() {
  yield takeLatest(AdminUsersActionConstants.DELETE_ADMIN_USER, deleteAdminUser);
}

export function* deleteAdminUser(action) {
  const response = yield call(deleteAdminUserApi, action.adminUserId);
  if (!response.error) {
    yield put(showToastr(ToastrType.success, "common:DELETED_SUCCESSFULLY", true));
    yield put({ offset: 0, type: AdminUsersActionConstants.FETCH_ADMIN_USERS });
  }
}
