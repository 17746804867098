// @ts-ignore
import { LoginActions } from "custom-actions-admin";

enum ActionTypeStates {
  AUTH_REQUEST = "AUTH_REQUEST",
  AUTH_RESULT = "AUTH_RESULT",
}

export const authRequest = (values) => ({
  payload: values,
  type: ActionTypeStates.AUTH_REQUEST,
});

export const authResult = (value) => ({
  isLoggedIn: value,
  type: ActionTypeStates.AUTH_RESULT,
});

export const getLoginMarketOrg = () => ({
  type: LoginActions.GET_LOGIN_MARKET_ORGANIZATIONS,
});

export const storeLoggedInMarketDetails = (marketOrgDetails?) => ({
  payload: marketOrgDetails,
  type: LoginActions.LOGGED_IN_MARKET_ORG,
});
