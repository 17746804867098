// @ts-ignore
import { TenantActions } from "custom-actions-admin";
import { put, select, takeLatest, call } from "redux-saga/effects";
import { showToastr, ToastrType } from "../actions/toastrAction";
import { t } from "i18next";
import {
  getMarketOrg,
  getTenantType,
  getLicenseTypes,
  getCountries,
  getStates,
  getCurrency,
  createTenant,
  getUserRoles,
  getCCMSDataByOriginCustomerId,
  deleteTenantAPI,
  getJobFunctions,
  getContactConflictDetails,
  scheduleDeleteTenantAPI,
} from "../api/createTenantApi";
import { FeatureFlagValue } from "../utils/enums/tenantEnum";
// @ts-ignore
import SingletonFeatureManager from "ADMIN_TARGET_BUILD/featureFlagManager/featureFlagManager";

function* fetchMarketOrgs() {
  const marketOrg = yield call(() => getMarketOrg());
  yield put({
    payload: marketOrg,
    type: TenantActions.PUT_MARKET_ORGANIZATION,
  });
}
function* fetchTenantsTypes(action) {
  const tenantTypeData = yield call(() => getTenantType(action.marketOrgId));
  yield put({ type: TenantActions.PUT_TENANT_TYPE, payload: tenantTypeData });
}
function* fetchLicenseTypes() {
  const response = yield call(() => getLicenseTypes());
  if (response && !response.error) {
    yield put({ type: TenantActions.PUT_TENANT_LICENSE, payload: response });
  }
}
function* fetchCountryList() {
  const countiresData = yield call(() => getCountries());
  yield put({ type: TenantActions.PUT_COUNTRIES_LIST, payload: countiresData });
}
function* fetchSateList(action) {
  const stateData = yield call(() => getStates(action.payload));
  yield put({ type: TenantActions.PUT_STATES_LIST, payload: stateData });
}
function* fetchCurrencyList() {
  const currencyData = yield call(() => getCurrency());
  yield put({ type: TenantActions.PUT_CURRENCY_LIST, payload: currencyData });
}

function* createTenantData(action) {
  const response = yield call(createTenant, action.tenantData);
  return yield handleTenantResponse(response, TenantActions.POST_TENANT_RESPONSE);
}

/**
 * Delete selected Tenant.
 */
function* deleteTenant(request) {
  let response = { error: "" };
  let message = "common:SCHEDULED_DELETED_SUCCESSFULLY";
  if (SingletonFeatureManager.isFeatureEnabled(FeatureFlagValue.TENANT_SCHEDULED_DELETE)) {
    response = yield call(() => scheduleDeleteTenantAPI(request));
    if (request.payload?.body.action === t("common:UNDO")) {
      message = "common:SCHEDULED_DELETED_CANCELLED";
    }
  } else {
    response = yield call(() => deleteTenantAPI(request.payload?.tenantId));
    message = "common:DELETED_SUCCESSFULLY";
  }
  if (!response.error) {
    yield put({
      marketOrg: yield select((state: any) => state.Login && state.Login.loggedInMarketOrg.marketOrgId),
      offset: 0,
      type: TenantActions.GET_TENANTS,
    });

    yield put({
      payload: null,
      type: TenantActions.SET_CHECKED_TENANT_DETAILS,
    });

    yield put(showToastr(ToastrType.success, message, true));
  }
}
/**
 * method to handle response from create Tenant
 *
 * @param response response from create tenant method
 * @param type type is the reducer type require to invoke
 */
export function* handleTenantResponse(response, type) {
  if (response === "" || (response && !response.isSystemError)) {
    if (response.error) {
      if (
        response.error.response &&
        response.error.response.data &&
        response.error.response.data.errors &&
        response.error.response.data.errors.length > 0
      ) {
        response = response.error.response.data.errors[0];
      } else {
        response = response.error;
      }
      yield put(showToastr(ToastrType.error, `errorCodes:${response.code}`, true));
      yield put({ type: TenantActions.POST_TENANT_FAIL, payload: response });
    } else {
      yield put({
        type: TenantActions.TENANT_UNSELECT_ALL_ROWS,
      });
      yield put({
        payload: null,
        type: TenantActions.SET_CHECKED_TENANT_DETAILS,
      });

      yield put({ type, payload: response });
      yield put({ payload: "", type: TenantActions.SET_TENANT_SEARCH_QUERY });
    }
  }
}

function* fetchUserRoles(action) {
  const userRolesData = yield call(() => getUserRoles(action.view));
  yield put({ type: TenantActions.PUT_USER_ROLES, payload: userRolesData });
}

function* fetchCCMSInfoByOriginCustomerId(action) {
  const response = yield call(() => getCCMSDataByOriginCustomerId(action.payload));
  const reponseStatus = !response.error ? response : true;
  if (!response.error) {
    yield put({
      payload: { data: response, originCustomerId: action.payload.originCustomerId },
      type: TenantActions.OM_GET_CCMS_INFO_SUCCESS,
    });
  } else {
    yield put({
      payload: { isError: true, originCustomerId: action.payload.originCustomerId },
      type: TenantActions.OM_GET_CCMS_INFO_SUCCESS,
    });
  }
}

export function* marketSaga() {
  yield takeLatest(TenantActions.GET_MARKET_ORGANIZATION, fetchMarketOrgs);
}
export function* tenantTypeSaga() {
  yield takeLatest(TenantActions.GET_TENANT_TYPE, fetchTenantsTypes);
}
export function* licenseTypesSaga() {
  yield takeLatest(TenantActions.GET_TENANT_LICENSE, fetchLicenseTypes);
}
export function* countryListSaga() {
  yield takeLatest(TenantActions.GET_COUNTRIES_LIST, fetchCountryList);
}
export function* stateListSaga() {
  yield takeLatest(TenantActions.GET_STATES_LIST, fetchSateList);
}
export function* currencyListSaga() {
  yield takeLatest(TenantActions.GET_CURRENCY_LIST, fetchCurrencyList);
}
export function* addTenantSaga() {
  yield takeLatest(TenantActions.POST_TENANT_DETAILS, createTenantData);
}

export function* userRolesSaga() {
  yield takeLatest(TenantActions.GET_USER_ROLES, fetchUserRoles);
}

export function* getCCMSinfoByOriginCustomerId() {
  yield takeLatest(TenantActions.OM_GET_CCMS_INFO, fetchCCMSInfoByOriginCustomerId);
}

export function* deleteTenantSaga() {
  yield takeLatest(TenantActions.DELETE_TENANT, deleteTenant);
}
/**
 * Saga to get worker job function.
 */

export function* getJobFunctionsDataSaga() {
  yield takeLatest(TenantActions.UM_GET_JOB_FUNCTIONS, getJobFunctionsData);
}

export function* getJobFunctionsData() {
  const marketOrgId = yield select((state: any) => state?.Login?.loggedInMarketOrg?.marketOrgId);
  const response = yield call(() => getJobFunctions(marketOrgId));
  if (response && !response.error) {
    yield put({ type: TenantActions.UM_GET_JOB_FUNCTIONS_SUCCESS, payload: response });
  }
}

export function* getContactConflictDetailsSaga() {
  yield takeLatest(TenantActions.UM_GET_CONTACT_CONFLICT, getContactConflict);
}

export function* getContactConflict(action) {
  const response = yield call(() => getContactConflictDetails(action.payload));
  if (response && !response.error) {
    yield put({
      payload: response,
      type: TenantActions.UM_GET_CONTACT_CONFLICT_SUCCESS,
    });
  }
}
