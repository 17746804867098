import { HelperLodash } from "am-web-ui-shared/helpers";
import { IGetQueryParams } from "../models/iGetQueryParams";
import { getPageSize } from "../utils/apiUtils";

const PAGE_SIZE = getPageSize();
/**
 * @description prepares query object for the GET of list calls
 */
export const getQueryObj = (state, offset, moduleName): IGetQueryParams => {
  const queryObj: IGetQueryParams = {
    limit: PAGE_SIZE,
    offset,
  };
  const modelState = HelperLodash.get(state, moduleName);
  if (modelState) {
    const sortFields = modelState.sortQuery;
    if (sortFields) {
      queryObj.order_by = sortFields.sortType + sortFields.sortField;
    }
    if (modelState.searchString) {
      queryObj.q = modelState.searchString;
    }
  }
  return queryObj;
};
