// @ts-ignore
import { TenantActions, AsyncExportActions } from "custom-actions-admin";
import { put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getDocIdOfRussianWorkers, getExportIdOfRussianWorkers, getTenants, sapLink, sapResync } from "../../api/index";
import { call } from "../common/commonSaga";
import { getQueryObj } from "../stateSelector";
import { validateResponse } from "../../utils/errorUtils";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import { localStorageSetObject, localStorageGetObject } from "../../utils/storageUtils";
// @ts-ignore
import { ExportStatus } from "ADMIN_TARGET_BUILD/config";
import { asyncExportSetting } from "../../utils/asyncExportUtils";
// @ts-ignore
import { HelperLodash } from "am-web-ui-shared/helpers";
import { removeCustomerToken } from "../../modules/customerAuthCallback/hiltiTokenStore";

export function* tenantsListSaga() {
  yield takeLatest(TenantActions.GET_TENANTS, fetchTenantsList);
}

export function* fetchTenantsList(action) {
  /*
offset : 0 -> Load with initial state, show grid loader only for initial state.
offset : (>0) -> Load list for pagination, paginated loader is shown by the infitescroll
component at the bottom of the page
*/

  if (!action.offset) {
    const loaderStartAction = { type: TenantActions.AM_TENANT_LIST_LOADER_START };
    yield put(loaderStartAction);
  }
  const querysObj = yield select(getQueryObj, action.offset, "Tenant");
  querysObj.marketOrg = action.marketOrg;
  let tenantData = yield call(() => {
    return getTenants(querysObj);
  });
  if (tenantData.error || !tenantData.response) {
    tenantData = { response: [], totalRecords: 0 };
  }
  const payloadObj = { tenantData, offset: action.offset };
  yield put({ type: TenantActions.PUT_TENANTS, payload: payloadObj });
  if (!action.offset) {
    yield put({ type: TenantActions.AM_TENANT_LIST_LOADER_END });
  }
  if (action.id) {
    yield put({ type: TenantActions.TENANT_SELECT_ROW, id: action.id });
  }

  removeCustomerToken();
}

export function* sapLinkHiltiNonConnectedAssetsSaga() {
  yield takeLatest(TenantActions.SAP_LINK, sapLinkHiltiNonConnectedAssets);
}

export function* sapLinkHiltiNonConnectedAssets(action) {
  const response = yield call(() => {
    return sapLink(action.tenantId);
  });
  if (response.error) {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, true));
    }
  } else {
    yield put(showToastr(ToastrType.success, "common:SAP_LINK_PROGRESS", true));
  }
}

export function* sapResyncHiltiNonConnectedAssetsSaga() {
  yield takeLatest(TenantActions.SAP_RESYNC, sapResyncHiltiNonConnectedAssets);
}

export function* sapResyncHiltiNonConnectedAssets(action) {
  const response = yield call(() => {
    return sapResync(action.tenantId);
  });
  if (response.error) {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `${errorResponse.message}`, true));
    }
  } else {
    yield put(showToastr(ToastrType.success, "common:SAP_RESYNC_PROGRESS", true));
  }
}

export function* exportTimeStampedUserDataSaga() {
  yield takeLatest(AsyncExportActions.EXPORT_TIMESTAMPED_USER_DATA, exportTimeStampedUserData);
}

function* exportTimeStampedUserData() {
  const response = yield call(() => getExportIdOfRussianWorkers());
  const { error, exportId, status } = response;

  if (error) {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, true));
    }
  } else if (exportId) {
    yield put(showToastr(ToastrType.info, `common:EXPORT_NOTIFY`, true));
    localStorageSetObject("exportInfo", {
      ...localStorageGetObject("exportInfo"),
      user: {
        exportStatus: status,
        exportId,
      },
    });
  }
}

export function* exportTimeStampedUserDataPollingSaga() {
  yield takeEvery(AsyncExportActions.EXPORT_TIMESTAMPED_USER_DATA_POLLING, exportTimeStampedUserDataPolling);
}

function* exportTimeStampedUserDataPolling() {
  const moduleName = "user";
  const exportInfo = localStorageGetObject("exportInfo");
  const response = yield call(() =>
    getDocIdOfRussianWorkers(Number(HelperLodash.get(exportInfo, moduleName, {}).exportId)),
  );
  const { error, docId, status } = response;

  if (error) {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, true));
    }
  } else if (status === ExportStatus.COMPLETED) {
    localStorageSetObject("exportInfo", {
      ...exportInfo,
      [moduleName]: {
        ...asyncExportSetting(moduleName, docId),
        exportStatus: status,
      },
    });
  } else if (status === ExportStatus.FAILED) {
    localStorageSetObject("exportInfo", { ...exportInfo, [moduleName]: { exportStatus: status } });
  }
}
