import { all } from "redux-saga/effects";
import {
  addTenantSaga,
  countryListSaga,
  currencyListSaga,
  deleteTenantSaga,
  getCCMSinfoByOriginCustomerId,
  getContactConflictDetailsSaga,
  getJobFunctionsDataSaga,
  marketSaga,
  stateListSaga,
  tenantTypeSaga,
  licenseTypesSaga,
  userRolesSaga,
} from "./createTenantSaga";
import {
  adminDetailsSaga,
  createCustomerAdminSaga,
  customerAdminsListSaga,
  editSupportAdminSaga,
} from "./customerAdmin/customerAdminSaga";
import { loginMarketOrgSaga } from "./login/loginSaga";
import { logoutSaga } from "./logout/logoutSaga";
import { getLanguageListSaga, timezoneListSaga } from "./masterData/masterDataSaga";
import {
  checkedTenantDetailsSaga,
  closeEditTenantSaga,
  defaultCountSaga,
  editTenantDetailsSaga,
  tenantDetailsSaga,
  updateTenantModulesSaga,
} from "./tenant/tenantDetailsSaga";
import {
  tenantsListSaga,
  sapResyncHiltiNonConnectedAssetsSaga,
  sapLinkHiltiNonConnectedAssetsSaga,
  exportTimeStampedUserDataSaga,
  exportTimeStampedUserDataPollingSaga,
} from "./tenant/tenantSaga";
import { tenantsStatusUpdateSaga } from "./tenant/tenantStatusUpdateSaga";
import { getUserDetailsByEmailId, promoteUserRole } from "./user/userSaga";
import { getUserMeDetailsSaga } from "./userProfile/userProfileSaga";
import { userSettingSaga } from "./userSetting/userSettingSaga";
import { addAdminUserSaga, fetchAdminUsersSaga, deleteAdminUserSaga } from "./adminUsers/adminUsersSaga";
import { getLicenseBundles } from "./tenant/licenseBundleSaga";

function* rootSaga() {
  yield all([
    countryListSaga(),
    currencyListSaga(),
    closeEditTenantSaga(),
    deleteTenantSaga(),
    adminDetailsSaga(),
    createCustomerAdminSaga(),
    editSupportAdminSaga(),
    getUserDetailsByEmailId(),
    getLanguageListSaga(),
    loginMarketOrgSaga(),
    marketSaga(),
    stateListSaga(),
    tenantTypeSaga(),
    licenseTypesSaga(),
    addTenantSaga(),
    tenantDetailsSaga(),
    tenantsListSaga(),
    tenantsStatusUpdateSaga(),
    logoutSaga(),
    editTenantDetailsSaga(),
    userRolesSaga(),
    getCCMSinfoByOriginCustomerId(),
    customerAdminsListSaga(),
    promoteUserRole(),
    getUserMeDetailsSaga(),
    userSettingSaga(),
    getJobFunctionsDataSaga(),
    getContactConflictDetailsSaga(),
    timezoneListSaga(),
    checkedTenantDetailsSaga(),
    updateTenantModulesSaga(),
    addAdminUserSaga(),
    fetchAdminUsersSaga(),
    deleteAdminUserSaga(),
    sapLinkHiltiNonConnectedAssetsSaga(),
    sapResyncHiltiNonConnectedAssetsSaga(),
    exportTimeStampedUserDataSaga(),
    exportTimeStampedUserDataPollingSaga(),
    defaultCountSaga(),
    getLicenseBundles(),
  ]);
}

export default rootSaga;
