// @ts-ignore
import { languages } from "ADMIN_TARGET_BUILD/config";
import { IAcceptLanguages, ILanguageList } from "./iLocalizationUtil";

/**
 * @description - Fallback language --in the case user's prefered or browser's language not found in the language list.
 */
const fallbackLanguage = "en-US";

/**
 * @description - This function is used to find the "language code" from the language list.
 * @param {ILanguageList[]} languageList - Language list.
 * @param {string} languageCode - Language code.
 *
 * @return {string} It returns the "language code", if that is found in the language list.
 */
const findUserLanguage = (languageList: ILanguageList[], languageCode: string): string => {
  let foundLanguage = null;
  if (languageList && languageList.length) {
    // Match the "language code" with the language list and return the code if it is found.
    foundLanguage = languageList.find((element) => {
      return element.code && element.code.toLowerCase() === languageCode.toLowerCase();
    });
  }
  return foundLanguage && foundLanguage.code ? foundLanguage.code : null;
};

/**
 * @description - This function is used to find the "language code" from the language list by matching it with browser's language having region e.g. "en-US", "de-DE" etc.
 *
 * @param {ILanguageList[]} languageList - Language list.
 * @param {string} languageCode - Language code.
 *
 * @return {string} It returns the "language code", if its match is found in the language list.
 */
const findUserLangWithoutRegion = (languageList: ILanguageList[], languageCode: string): string => {
  // Match the browser "language code" with the first two characters of the language list and return the code if it is found.
  // It is used in the case browser language is not having region part. e.g. "IN" is missing from "en-IN".
  const foundWithoutRegion = languageList.find((element) => {
    if (
      element.code &&
      element.code.split("-").length &&
      element.code.split("-")[0].toLowerCase() === languageCode.toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  });
  return foundWithoutRegion ? foundWithoutRegion.code : fallbackLanguage;
};

/**
 * @description - This function is used to get the browser's prefered language from the "accept-language" list.
 *
 * @param {IAcceptLanguages[]} browserLang - List of the browser's accept-language.
 *
 * @return {string} - It returns the user's browser's accept-language code.
 */
export const getBrowserPreferredLang = (browserLang: IAcceptLanguages[]): string => {
  // Check if there is a list of browser's accept-language.
  if (browserLang && browserLang.length) {
    // If region is present in the list, then concatinate the "code" and "region" with hyphen, and then return that.
    if (browserLang[0].region) {
      return "".concat(browserLang[0].code, "-", browserLang[0].region);
    } else {
      // If region is NOT present in the list, then return the code only.
      return "".concat(browserLang[0].code);
    }
  }
  return fallbackLanguage;
};

/**
 * @description - This function is used to find the "language code" from the language list by matching it with browser's accept-language code.
 *
 * @param {ILanguageList[]} langList - Language list.
 * @param {IAcceptLanguages[]} browserLang - Browser's accept-language list.
 *
 * @return {string} - It returns the browser's accept-language code if it us found in the language list.
 */
export const getBrowserLangByLangList = (langList: ILanguageList[], browserLang: IAcceptLanguages[]): string => {
  // Check if there is a list of browser's accept-language.
  if (browserLang && browserLang.length) {
    // If region is present in the list, then find it into the language list and return if found.
    if (browserLang[0].region) {
      return findUserLanguage(langList, `${browserLang[0].code}-${browserLang[0].region}`) || fallbackLanguage;
    } else {
      // If region is NOT present in the list, then find the two character code from the language list and return the first matching language code.
      return findUserLangWithoutRegion(langList, browserLang[0].code);
    }
  }
  return fallbackLanguage;
};
/**
 * @description - This function is used get language from API if no language then it take from config
 * @param {ILanguageList[]} langList - Language list.
 */
const getLanguage = (langList) => {
  return langList && langList.length > 0 ? langList : languages;
};
/**
 * @description - This function is used to find the most suitable language for the application depending upon various conditions explained into the function.
 *
 * @param {ILanguageList[]} langList - Language list.
 * @param {IAcceptLanguages[]} browserLang - Browser's accept-language list.
 */
const getApplicationLanguage = (langList: ILanguageList[], browserLang: IAcceptLanguages[]): string => {
  langList = getLanguage(langList);
  // If list of the languages is avilable.
  if (langList && langList.length) {
    // If user language is not found into the list, then check for the browser's accept language.
    if (browserLang && browserLang.length) {
      // If browser language is found and the language is having region with it. e.g. "en-US", here "US" is region.
      if (browserLang[0].region) {
        // Find if the browser language is avilable into the language list, then return that code else return the fallback language i.e. "en-US".
        const foundCodeWithRegion = findUserLanguage(langList, `${browserLang[0].code}-${browserLang[0].region}`);
        return foundCodeWithRegion ? foundCodeWithRegion : fallbackLanguage;
      } else {
        // If the bowser language is not having the region with it. e.g. "de", "en" etc.
        return findUserLangWithoutRegion(langList, browserLang[0].code);
      }
    } else {
      return fallbackLanguage;
    }
  } else {
    // Find the browser langauge into language list and return if found.
    return getBrowserLangByLangList(langList, browserLang);
  }
};

/**
 * @export getApplicationLanguage - Export the function that can be used to get the most suitable language for the application.
 */
export default getApplicationLanguage;
