import { HelperLodash, LocaleManager } from "am-web-ui-shared/helpers";

export const asyncExportSetting = (moduleName: string, documentId: string) => {
  const setting = {
    user: {
      fileName: `Audit_${LocaleManager.dateHelpers.unixTimestamp()}.xls`,
    },
  };

  return { ...HelperLodash.get(setting, moduleName), documentId };
};
