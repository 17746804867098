import { HelperLodash } from "am-web-ui-shared/helpers";
import { createReducer } from "../../utils/commonUtils";
import { setRowSelection, clearAllSelection } from "../../utils/listUtils";

const AdminUserPrimaryKey = "userId";

const adminUsersInitialState = {
  addAdminUserResponse: null,
  adminUsersInfo: {
    response: [],
    totalRecords: null,
    limit: null,
    offset: null,
  },
  isListLoading: false,
};

const addAdminUserSuccess = (state, action) => {
  return {
    ...state,
    addAdminUserResponse: action.payload,
  };
};

const saveAdminUsers = (state, action) => {
  const response = action.offset
    ? [...state.adminUsersInfo.response, ...action.payload.response]
    : action.payload.response;
  const payload = { ...action.payload, response };
  return {
    ...state,
    adminUsersInfo: payload,
  };
};

const selectRow = (state, action) => {
  const adminUserList = clearAllSelection(HelperLodash.cloneDeep(state.adminUsersInfo.response));
  return {
    ...state,
    adminUsersInfo: {
      ...state.adminUsersInfo,
      response: setRowSelection(adminUserList, action.id, true, AdminUserPrimaryKey),
    },
  };
};

const unselectRow = (state, action) => {
  const adminUserList = HelperLodash.cloneDeep(state.adminUsersInfo.response);
  return {
    ...state,
    adminUsersInfo: {
      ...state.tenantsList,
      response: setRowSelection(adminUserList, action.id, false, AdminUserPrimaryKey),
    },
  };
};

const startInitialLoader = (state) => {
  return {
    ...state,
    isListLoading: true,
  };
};

const stopInitialLoader = (state) => {
  return {
    ...state,
    isListLoading: false,
  };
};

const adminUsersReducer = createReducer(adminUsersInitialState, {
  ADD_ADMIN_USER_SUCCESS: addAdminUserSuccess,
  FETCH_ADMIN_USERS_SUCCESS: saveAdminUsers,
  ADMIN_USERS_LOADER_START: startInitialLoader,
  ADMIN_USERS_LOADER_END: stopInitialLoader,
  SELECT_ADMIN_USER: selectRow,
  DESELECT_ADMIN_USER: unselectRow,
});

export default adminUsersReducer;
