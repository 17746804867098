import { put, takeLatest } from "redux-saga/effects";
import LicenseBundleActions from "../../actionConstants/licenseBundleActionConstant";
import { call } from "../common/commonSaga";
import { getLicenseBundlesResponse } from "../../api";

export const getLicenseBundles = function* () {
  yield takeLatest(LicenseBundleActions.LIST_LICENSE_BUNDLES, fetchLicenseBundles);
};

const fetchLicenseBundles = function* (action) {
  const response = yield call(() => {
    return getLicenseBundlesResponse(action.tenantCategory);
  });

  if (!response.error) {
    yield put({ type: LicenseBundleActions.PUT_LICENSE_BUNDLES, payload: response });
  }
};
