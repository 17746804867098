/**
 * @description This file is for api hitting using
 * Gateway for the edit Tenant web UI user.
 */

import Gateway from "../../common/gateway/gateway";
import { ITenantModules } from "../models/iTenantModules";
import { ITenantDetailsData } from "../models/tenantDetails";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description Function to create tenant
 * @memberof EditTenant Module
 */
export default class EditTenantAPI {
  /**
   * @description Returns the tenant detail from API
   *
   * @static
   * @memberof EditTenantApi
   * @param {} : empty object
   * @header tenantData : user added data in the form
   */
  static editTenantDetails = async (tenantId, item: ITenantDetailsData): Promise<any> => {
    const url = getOrgMgmtEndpoint(`/tenants/${tenantId}`);
    return Gateway.put(url, null, item, null) as unknown as ITenantDetailsData;
  };

  /**
   * @description API to update multiple tenant module status
   * @param {} : payload object of type ITenantModules
   */
  static editTenantModulesStatus = async (payload: ITenantModules): Promise<any> => {
    const url = getOrgMgmtEndpoint(`/tenants/modules`);
    return Gateway.patch(url, null, payload, null) as unknown as any;
  };
}
