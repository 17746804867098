import { HelperLodash } from "am-web-ui-shared/helpers";
import { put, select, takeLatest } from "redux-saga/effects";
import TenantActions from "../../actionConstants/tenantActionConstants";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import { updateTenantActivationStatus } from "../../api/index";
import { IErrorResponse } from "../../models/iApiError";
import { TenantStatus } from "../../modules/tenant/tenantStatus";
import { call } from "../common/commonSaga";

export function* tenantsStatusUpdateSaga() {
  yield takeLatest(TenantActions.UPDATE_TENANT_ACTV_STATUS, changeTenantActivationStatus);
}

export function* changeTenantActivationStatus(action) {
  let APIResponse: IErrorResponse | number = null;
  let newPayload: { id: number; isTenantStatusUpdated: boolean } = null;
  let isSuccess = false;
  const status = HelperLodash.get(action, "payload.tenantActivationModel.tenantStatus");
  if (HelperLodash.get(action, "payload.id")) {
    APIResponse = yield call(() => {
      return updateTenantActivationStatus(action.payload);
    });

    if (APIResponse && (APIResponse as IErrorResponse).error) {
      newPayload = {
        id: null,
        isTenantStatusUpdated: false,
      };

      if (status === TenantStatus.ACTIVE) {
        yield put(showToastr(ToastrType.success, `tenants:ACTIVATION_ON_FAILURE_NOTIFICATION`, true));
      } else {
        yield put(showToastr(ToastrType.success, `tenants:DEACTIVATION_ON_FAILURE_NOTIFICATION`, true));
      }
    } else {
      newPayload = {
        id: action.payload.id,
        isTenantStatusUpdated: true,
      };
      isSuccess = true;
    }
  }

  yield put({
    payload: newPayload,
    type: TenantActions.TENANT_ACTIVATION_STATUS,
  });
  if (isSuccess) {
    if (status === TenantStatus.ACTIVE) {
      yield put(showToastr(ToastrType.success, `tenants:ACTIVATION_ON_SUCCESS_NOTIFICATION`, true));
    } else {
      yield put(showToastr(ToastrType.success, `tenants:DEACTIVATION_ON_SUCCESS_NOTIFICATION`, true));
    }

    yield put({
      marketOrg: yield select((state: any) => state.Login && state.Login.loggedInMarketOrg.marketOrgId),
      offset: 0,
      type: TenantActions.GET_TENANTS,
    });
    yield put({
      payload: null,
      type: TenantActions.SET_CHECKED_TENANT_DETAILS,
    });
    yield put({
      payload: null,
      type: TenantActions.SET_TENANT_DETAILS,
    });
  }
}
