/**
 * @description This file is for api hitting using
 * Gateway for the create Tenant web UI user.
 */
import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import ICustomerAdminRolesModel from "../models/iCustomerAdminRolesModel";
import {
  IExistingCustomer,
  IDefaultLocation,
  IAssetOnboarding,
} from "../modules/tenant/addCustomerTenant/addTenant/iExistingCustomerModel";
import { getOrgMgmtEndpoint, getUserMgmtEndpoint } from "../utils/apiUtils";
import ITenantDeleteResponseModel from "../models/tenantDeleteResponseModel";
/**
 * @description Function to create tenant
 * @memberof CreateTenant Module
 */

/**
 * Returns list of market organisations by calling relevant API
 */
export const getMarketOrg = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/market-organizations"), null, null);
};
/**
 * Returns list of tenant types by calling relevant API
 */
export const getTenantType = async (marketOrgId: string): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint(`/tenants/types/${marketOrgId}`), null, null);
};
/**
 * Returns list of tenant Licenses by calling relevant API
 */
export const getLicenseTypes = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/app-license-types"), null, null);
};
/**
 * Returns list of countries by calling relevant API
 */
export const getCountries = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/countries"), null, null);
};
/**
 * Returns list of states by calling relevant API
 * @param countryCode : code of selected country
 */
export const getStates = async (countryCode: string): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/states"), { countryCode }, null) as unknown;
};
/**
 * Returns list of currencies by calling relevant API
 */
export const getCurrency = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint("/currencies"), null, null);
};

/**
 * @description Returns the respose of Add tenant from API
 *
 * @export const
 * @memberof CreatetenantApi
 * @param {} : empty object
 * @header tenantData : user added data in the form
 */
export const createTenant = async (tenantData): Promise<any> => {
  // isMigrated: false, is used by org mgmnt to deduce the tenant is created by admin portal and keep origin as onTrack.
  tenantData.isMigrated = false;
  try {
    const params = {};
    const url = getOrgMgmtEndpoint("/tenants");
    return Gateway.post(url, params, tenantData, null);
  } catch (error) {
    return { error: "Unknown error" };
  }
};
/**
 * Returns list of User Roles by calling relevant API
 */
export const getUserRoles = async (view: string): Promise<ICustomerAdminRolesModel | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint(`/roles?view=${view}`), null, null) as unknown as ICustomerAdminRolesModel;
};

/**
 * @description Returns the respose of CCMS data
 *
 * @export const
 * @param {id} : Customer id
 * @returnType {Promise} : Returns a promise
 */
export const getCCMSDataByOriginCustomerId = async (payload): Promise<IExistingCustomer | IErrorResponse> => {
  const params = {
    market_org_id: payload.marketOrgId,
  };
  return Gateway.get(
    getOrgMgmtEndpoint(`/customer/${payload.originCustomerId}`),
    params,
    null,
  ) as unknown as IExistingCustomer;
};

export const deleteTenantAPI = async (tenantId): Promise<any> => {
  return Gateway.delete(getOrgMgmtEndpoint(`/tenants/${tenantId}`), null, null) as unknown;
};

export const scheduleDeleteTenantAPI = async (request): Promise<ITenantDeleteResponseModel | IErrorResponse> => {
  return Gateway.patch(
    getOrgMgmtEndpoint(`/tenants/${request.payload?.tenantId}`),
    null,
    request.payload.body,
    null,
  ) as unknown as ITenantDeleteResponseModel;
};

export const getJobFunctions = async (marketOrgId): Promise<IExistingCustomer | IErrorResponse> => {
  return Gateway.get(
    getUserMgmtEndpoint(`/market-organizations/${marketOrgId}/ccms-contacts/job-functions`),
    null,
    null,
  ) as unknown as IExistingCustomer;
};

/**
 * @description contact details
 *
 * @memberof workerApi
 * @param data.
 */
export const getContactConflictDetails = async (data) => {
  const extendedData = {
    ...data,
    isExtendAccess: true,
  };
  return Gateway.post(
    getUserMgmtEndpoint(`/tenants/${data.tenantId}/ccms-contacts/find-contacts`),
    null,
    extendedData,
    null,
  );
};

/**
 * @description add the defualt location for tenant
 *
 * @export const
 * @memberof CreatetenantApi
 * @param {} : empty object
 */
export const addDefaultLocation = async (tenantId: number): Promise<IDefaultLocation | IErrorResponse> => {
  const params = {};
  const url = getOrgMgmtEndpoint(`/tenants/${tenantId}/default-location`);
  return Gateway.post(url, params, {}, null) as unknown as IDefaultLocation;
};

/**
 * @description asset onboarding
 *
 * @export const
 * @memberof CreatetenantApi
 * @param {} : empty object
 */
export const addAssetOnboarding = async (
  tenantId: number,
  employeeId: number,
  defaultLocationId: number,
): Promise<IAssetOnboarding | IErrorResponse> => {
  const params = {};
  const body = {
    registrationMode: "AUTO",
    defaultLocationId: defaultLocationId,
    defaultEmployeeId: employeeId,
    registerExistingTools: true,
  };
  const url = getOrgMgmtEndpoint(`/tenants/${tenantId}/asset-onboarding`);
  return Gateway.post(url, params, body, null) as unknown as IAssetOnboarding;
};
