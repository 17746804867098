// @ts-ignore
import { TenantActions } from "custom-actions-admin";
import { ITenantReducerState } from "../../models/iTenantReducerState";

/**
 * @description Tenant reducer constants
 */

const tenantLoaderReducer = (
  state: ITenantReducerState = {
    checkedTenantDetails: null,
    resetScrollPosition: false,
    tenantDetails: null,
    tenantsList: null,
  },
  action: { type: any; payload?: any },
) => {
  switch (action.type) {
    case TenantActions.AM_TENANT_LIST_LOADER_END:
      return {
        ...state,
        loader: {
          isDetailsLoading: false,
          isListLoading: false,
        },
      };
    case TenantActions.AM_TENANT_LIST_LOADER_START:
      return {
        ...state,
        loader: {
          isDetailsLoading: false,
          isListLoading: true,
        },
      };
    default:
      return state;
  }
};

export default tenantLoaderReducer;
