import { HelperLodash } from "am-web-ui-shared/helpers";
import ApplicationConstants from "../applicationConstants";

/**
 * @description locationHref should route to the url
 * @param url <string>
 *
 */
export const locationHref = (url: string) => {
  window.location.href = url;
};

/**
 * @description Function returns true or false according to the x
 * @param {String} - x - is any string value
 */
export const isEmptyStringOrValue = (x: string) => {
  return !!(x || x === "");
};

/**
 * use to set scroll position where user receive error
 * @param errorFIelds its a collection of field receive validation error
 */
export function setScrollOnField() {
  setTimeout(() => {
    const elements = document.getElementsByClassName("errorLabel");
    if (elements.length) {
      elements[0].scrollIntoView();
    }
  }, ApplicationConstants.TIMEOUT.TOUT100);
}

export const getPropertyLabel = (property: string, t: any) => {
  return property && t(`common:PROPERTY_${property.toUpperCase()}`);
};

/**
 * @description - Function to get object property value from list of objects.
 * @param list (Array) - List of objects.
 * @param value (string | number) - Value of object key to match.
 * @param key1 (string) - Object property to match with passed value. Default property is code.
 * @param key2 (string) - Object property to return value. Default property is value.
 */
export const getListObjectValue = (list, value: string | number, key1 = "code", key2 = "value") => {
  const obj = list && list.find((column: object) => HelperLodash.get(column, key1) === value);
  return obj && HelperLodash.get(obj, key2);
};

/**
 * @description To filter array with unique value
 * @param {array} array with nested object.
 */

export const uniqueArray = (array) => {
  return HelperLodash.uniqWith(array, HelperLodash.isEqual);
};

/**
 * Function to return formatted contact number.
 * @param - contactNumber
 */
export const getDisplayContact = (contactDetails: { countryCode: string; phoneNumber: string }) => {
  const countryCode = getCountryCode(contactDetails);
  const phoneNumber = getPhoneNumber(contactDetails);
  return countryCode ? `+${countryCode} ${phoneNumber}` : `${phoneNumber}`;
};

export const getCountryCode = (contactDetails: { countryCode: string; phoneNumber: string }) => {
  return contactDetails && contactDetails.countryCode ? contactDetails.countryCode : "";
};

export const getPhoneNumber = (contactDetails: { countryCode: string; phoneNumber: string }) => {
  return contactDetails && contactDetails.phoneNumber ? contactDetails.phoneNumber : "";
};

/**
 * @description - Function to create the reducer function
 * @param initialState-- state passed to the reducer function
 * @param handlers-- function to execute on specific action type
 */
export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
  };

/**
 * @description - Check module is Enabled or in grace period
 * @param details -- tenant details
 * @param moduleName -- moduleName
 */
export const isModuleEnabledOrInGracePeriod = (details, moduleName) => {
  const moduleDetails = (details?.listOfModules || []).find((item) => item.name === moduleName);
  return moduleDetails?.status || (details?.listOfModuleInGrace || []).some((item) => item.name === moduleName);
};

/**
 * @description - Check module is in grace period
 * @param details -- tenant details
 * @param moduleName -- moduleName
 */
export const isModuleInGracePeriod = (details, moduleName) => {
  return (details?.listOfModuleInGrace || []).some((item) => item.name === moduleName);
};
