// @ts-ignore
import { TenantActions } from "custom-actions-admin";
import { put, select, takeLatest } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import EditTenantAPI from "../../api/editTenantApi";
import { getAssetCountAPI, getTenant } from "../../api/index";
import { validateResponse } from "../../utils/errorUtils";
import { call } from "../common/commonSaga";
import store from "../../store/store";
import SingletonFeatureManager from "../../../featureFlagManager/featureFlagManager";
import { addAssetOnboarding, addDefaultLocation } from "../../api/createTenantApi";
import { Onboarding, RoleValue, LicenseTypeEnum } from "../../utils/enums/tenantEnum";

export function* tenantDetailsSaga() {
  yield takeLatest(TenantActions.GET_TENANT_DETAILS, fetchTenantDetails);
}

export function* fetchTenantDetails(action) {
  const marketOrgId = store.getState().Login.loggedInMarketOrg?.marketOrgId;
  const rowData = yield call(() => {
    return getTenant(action.tenantId, { marketOrg: marketOrgId });
  });
  yield put({ type: TenantActions.GET_DEFAULT_COUNT, payload: action.tenantId });
  yield put({ type: TenantActions.SET_TENANT_DETAILS, payload: rowData });
  const tenant = yield select((state) => state?.Tenant?.tenantDetails);
  const licenseType = tenant?.licenseType;
  const userRole = tenant?.users?.[0]?.role;
  if (
    SingletonFeatureManager.isFeatureEnabled(Onboarding.asset) &&
    userRole === RoleValue.ADMIN &&
    licenseType === LicenseTypeEnum.FM
  ) {
    const response = yield addDefaultLocation(tenant.tenantId);
    if ("error" in response) {
      return;
    }
    if (response) {
      addAssetOnboarding(tenant.tenantId, tenant.users[0]?.workerId, response?.id);
    }
  }
}

export function* defaultCountSaga() {
  yield takeLatest(TenantActions.GET_DEFAULT_COUNT, getDefaultCount);
}

export function* getDefaultCount(action: { payload: number }) {
  const defaultCountList = yield call(getAssetCountAPI, { ids: [action.payload], entity: "DEFAULT" });
  if (!defaultCountList.error) {
    yield put({ type: TenantActions.PUT_TENANT_DEFAULT_COUNT, payload: defaultCountList });
  }
}

/**
 * @description the saga function to edit tenant details.
 *
 * @export
 */
export function* editTenantDetailsSaga() {
  yield takeLatest(TenantActions.EDIT_TENANT_DETAILS, editTenantDetails);
}

/**
 * @description the function to edit tenant details.
 *
 * @export
 * @param action - the action object
 */
function* editTenantDetails(action) {
  const response = yield call(() => {
    return EditTenantAPI.editTenantDetails(action.tenantId, action.item);
  });
  if (!response.error) {
    yield put({
      payload: { item: action.item, tenantId: action.tenantId },
      type: TenantActions.UPDATE_TENANT_LIST,
    });
    yield put({
      payload: true,
      type: TenantActions.EDIT_TENANT_DETAILS_SUCCESS,
    });
    yield put({ payload: "", type: TenantActions.SET_TENANT_SEARCH_QUERY });
    yield put({
      payload: null,
      type: TenantActions.SET_CHECKED_TENANT_DETAILS,
    });
  } else {
    if (
      response.error &&
      response.error.response &&
      response.error.response.data &&
      response.error.response.data.errors
    ) {
      yield put({
        payload: response.error.response.data.errors[0].code,
        type: TenantActions.EDIT_TENANT_DETAILS_FAIL,
      });
    }
  }
}

/**
 * @description the saga function to close edit tenant form.
 *
 * @export
 */
export function* closeEditTenantSaga() {
  yield takeLatest(TenantActions.CLOSE_EDIT_TENANT, closeEditTenant);
}

/**
 * @description the function to close edit tenant form.
 *
 * @export
 */
function* closeEditTenant() {
  yield put({
    payload: false,
    type: TenantActions.EDIT_TENANT_DETAILS_SUCCESS,
  });
}

export function* checkedTenantDetailsSaga() {
  yield takeLatest(TenantActions.GET_CHECKED_TENANT_DETAILS, fetchCheckedTenantDetails);
}

export function* fetchCheckedTenantDetails(action) {
  const marketOrgId = store.getState().Login.loggedInMarketOrg?.marketOrgId;
  const rowData = yield call(() => {
    return getTenant(action.tenantId, { marketOrg: marketOrgId });
  });

  if (!rowData.error) {
    yield put({ type: TenantActions.SET_CHECKED_TENANT_DETAILS, payload: rowData });
  }
}

export function* updateTenantModulesSaga() {
  yield takeLatest(TenantActions.OM_TENANT_MODULE_UPDATE, editTenantModules);
}

export function* editTenantModules(action) {
  const response = yield call(() => {
    return EditTenantAPI.editTenantModulesStatus(action.payload);
  });
  if (!(response.error || response.errors)) {
    yield put({
      marketOrg: yield select((state: any) => state.Login && state.Login.loggedInMarketOrg.marketOrgId),
      offset: 0,
      type: TenantActions.GET_TENANTS,
    });
    yield put({ type: TenantActions.SET_CHECKED_TENANT_DETAILS, payload: null });
    yield put(showToastr(ToastrType.success, "tenants:TENANT_UPDATED_SUCCESSFULLY", true));
  } else {
    const errorResponse = validateResponse(response);
    if (errorResponse) {
      // for partial update response (errorcode:2298) , call tenant list
      if (errorResponse.code === "2298") {
        yield put({
          marketOrg: yield select((state: any) => state.Login && state.Login.loggedInMarketOrg.marketOrgId),
          offset: 0,
          type: TenantActions.GET_TENANTS,
        });
        yield put({ type: TenantActions.SET_CHECKED_TENANT_DETAILS, payload: null });
      }
      yield put(showToastr(ToastrType.error, `errorCodes:${errorResponse.code}`, true));
    }
  }
}
