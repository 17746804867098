// @ts-ignore
import { UserActions } from "custom-actions-admin";

/**
 * @description tenant reducer constants
 *
 * @param state : current state of store
 * @param action : action for which reducer will reduce
 */
const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case UserActions.PUT_USER_BYEMAIL_FAIL:
      return {
        ...state,
        userByEmailError: action.payload,
        userByEmailId: null,
      };
    case UserActions.PUT_USER_BYEMAIL_SUCCESS:
      return {
        ...state,
        userByEmailError: null,
        userByEmailId: action.payload,
      };
    case UserActions.UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        updateUserError: action.payload,
        updateUserSuccess: false,
      };
    case UserActions.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updateUserError: null,
        updateUserSuccess: true,
      };
    case UserActions.CLEAR_UPDATE_USER_ROLE:
      return {
        ...state,
        updateUserError: null,
        updateUserSuccess: null,
        userByEmailError: null,
        userByEmailId: null,
      };
    default:
      return state;
  }
};

export default usersReducer;
