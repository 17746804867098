import React from "react";
import { ILazyModule } from "./iLazyModuleProps";
import { ILazyModuleState } from "./iLazyModuleState";

class LazyLoadModule extends React.Component<ILazyModule, ILazyModuleState> {
  // state initialization
  readonly state: ILazyModuleState = {
    error: null,
    isError: false,
    module: null,
  };

  componentDidCatch(error) {
    this.setState({ isError: true, error });
  }

  async componentDidMount() {
    try {
      const { resolve } = this.props;
      const { default: module } = await resolve();
      this.setState({ module });
    } catch (error) {
      this.setState({ isError: true, error });
    }
  }

  render() {
    const { module } = this.state;
    const { resolve, ...rest } = this.props;

    if (!module) {
      return <div>Loading module...</div>;
    }

    if (module.view) {
      return React.createElement(module.view, rest);
    }

    return <div>Module loaded</div>;
  }
}

export default LazyLoadModule;
