/**
 * @description This file is for api hitting using
 * Gateway for getting master Data.
 */

import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";

/**
 * Returns list of timezone by calling relevant API
 */
export const getTimezone = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getOrgMgmtEndpoint(`/timezones`), null, null);
};

/**
 * Returns list of Language
 */
export const getLanguage = async (): Promise<any> =>
  Gateway.getUnAuthorized("/languages", { isLanguageNameList: true });
