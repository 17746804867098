// @ts-ignore
import { TenantActions } from "custom-actions-admin";

/**
 * @description tenant reducer constants
 */
export const createTenantReducer = (state = {}, action) => {
  switch (action.type) {
    case TenantActions.GET_COUNTRIES_LIST:
      return {
        ...state,
        countryList: action.payload,
      };
    case TenantActions.GET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload,
      };
    case TenantActions.GET_LANGUAGE_LIST:
      return {
        ...state,
        languageList: action.payload,
      };
    case TenantActions.GET_MARKET_ORGANIZATION:
    case TenantActions.PUT_MARKET_ORGANIZATION:
      return {
        ...state,
        marketOrg: action.payload,
      };
    case TenantActions.GET_STATES_LIST:
      return {
        ...state,
        stateList: action.payload,
      };
    case TenantActions.GET_TENANT_TYPE:
      return {
        ...state,
        tenantType: action.payload,
      };
    default:
      return tenantReducerCreator(state, action);
  }
};
const tenantReducerCreator = (state = {}, action) => {
  switch (action.type) {
    case TenantActions.PUT_COUNTRIES_LIST:
      return {
        ...state,
        putCountriesList: action.payload,
      };
    case TenantActions.PUT_CURRENCY_LIST:
      return {
        ...state,
        putCurrencyList: action.payload,
      };
    case TenantActions.PUT_LANGUAGE_LIST:
      return {
        ...state,
        putLanguageList: action.payload,
      };
    case TenantActions.PUT_STATES_LIST:
      return {
        ...state,
        putStateList: action.payload,
      };
    case TenantActions.PUT_TENANT_TYPE:
      return {
        ...state,
        putTenantType: action.payload,
      };
    case TenantActions.POST_TENANT_DETAILS:
      return {
        ...state,
      };
    case TenantActions.POST_TENANT_RESPONSE:
      return {
        ...state,
        postTenantError: action.error ? action.payload : null,
        postTenantResponse: !action.error ? action.payload : null,
      };
    default:
      return tenantReducers(state, action);
  }
};
const tenantReducers = (state = {}, action) => {
  switch (action.type) {
    case TenantActions.POST_TENANT_FAIL:
      return {
        ...state,
        postTenantError: action.payload.code,
        postTenantResponse: null,
      };
    case TenantActions.CLEAR_POST_TENANT_RESPONSE:
      return {
        ...state,
        contactConflictResponse: undefined,
        postTenantError: null,
        postTenantResponse: null,
      };
    case TenantActions.GET_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload,
      };
    case TenantActions.PUT_USER_ROLES:
      return {
        ...state,
        putUserRoles: action.payload?.sort((a, b) => a.code.localeCompare(b.code)),
      };
    case TenantActions.OM_GET_CCMS_INFO_SUCCESS:
      return {
        ...state,
        dataByCustomerId: action.payload,
      };
    case TenantActions.UM_GET_JOB_FUNCTIONS_SUCCESS:
      return {
        ...state,
        jobFunctions: action.payload,
      };
    case TenantActions.PUT_TENANT_LICENSE:
      return {
        ...state,
        licenseTypes: action.payload,
      };
    default:
      return state;
  }
};
