import acceptLanguageParser from "accept-language-parser";
import { LocaleManager } from "am-web-ui-shared/helpers";
// @ts-ignore
import { UserSettingActions } from "custom-actions-admin";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getFormatFromLocale } from "../../api/formatterApi";
import { getLanguage } from "../../api/masterDataApi";
import { getBrowserLanguage } from "../../api/userSettingApi";
import getApplicationLanguage from "../../utils/localization/localizationUtil";

/**
 * Saga to fetch user setting.
 */
export function* userSettingSaga() {
  yield takeLatest(UserSettingActions.UM_GET_USER_SETTING, userSettingFn);
}

/**
 * Saga to select the appropiate language locale.
 */
export function* userSettingFn() {
  const [languageList, acceptHeaderLang] = yield all([call(getLanguage), call(getBrowserLanguage)]);
  const userSettingData: any = {};
  let acceptHeader = acceptHeaderLang;
  if (!acceptHeader) {
    acceptHeader = { "accept-language": "en-US" };
  }
  const acceptLanguages = acceptLanguageParser.parse(acceptHeader["accept-language"]);
  userSettingData.applyLanguageCode = getApplicationLanguage(languageList, acceptLanguages);
  yield call(getFormattedDate, userSettingData);
  yield put({ type: UserSettingActions.UM_USER_SETTING_RESPONSE, payload: userSettingData });
}

/**
 * @description Saga to get formatted date.
 * @memberof userSettingSaga
 */
export function* getFormattedDate(userSettingData) {
  LocaleManager.dateHelpers.setLocale(userSettingData.applyLanguageCode);
  const format = yield call(getFormat, userSettingData.applyLanguageCode);
  if (format && !format.error && format[0]) {
    LocaleManager.dateHelpers.setCustomFormat(format[0]);
  }
}

/**
 * @description function to get response from getFormatFromLocale
 * @memberof userSettingSaga
 */
export function* getFormat(locale) {
  let response = yield call(() => {
    return getFormatFromLocale(locale);
  });
  if (response && response.error) {
    response = [];
  }
  return response;
}
