const ValidationConstants = {
  // To Verify alpha characters (A-Za-z), digits and "-" require for Postal Code(Add Worker)
  ALPHA_NUMERIC_HYPHEN: /^[a-z0-9-\s]+$/i,
  // To Verify alpha characters (A-Za-z), digits and -, ., $, /, +, %,  and space require for Scan Code(Add Worker)
  ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR: /^[a-z0-9-.$/+% \s]+$/i,
  DOMAIN_REGEX: /(^(?!_)(?!-)(?!www.)[a-zA-Z0-9_\-\.]+)\.(\d*[a-zA-Z][a-zA-Z\d]*)$/,
  // To Verify Email(Add Worker)
  EMAIL_REGEX: /^([a-zA-Z0-9_\-'.]+)@((?!_)(?!-)(?!www.)[a-zA-Z0-9_\-\.]+)\.(\d*[a-zA-Z][a-zA-Z\d]*)$/,
  MAX_LENGTH: {
    NUM_10: 10,
    NUM_100: 100,
    NUM_1024: 1024,
    NUM_128: 128,
    NUM_132: 132,
    NUM_16: 16,
    NUM_2048: 2048,
    NUM_25: 25,
    NUM_256: 256,
    NUM_3: 3,
    NUM_32: 32,
    NUM_36: 36,
    NUM_64: 64,
    NUM_67: 67,
  },
  NUMBER_REGEX: /^\d+$/,
  NUMBER_REGEX_PHONE_NUMBER: /^$|^([0-9() +-]*)$/,
  // IP_RANGE_REGEX: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2})/,  //temporaryly removed due to security issue
};

export { ValidationConstants };
