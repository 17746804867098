export enum ModuleNameEnum {
  ASSET_COST = "ASSET_COST",
  QTY_ITEMS = "QTY_ITEMS",
  DASHBOARD = "DASHBOARD",
  // the CIS feature was renamed 'UNITE' during feature definition (BUTSAM-101906)
  // However, apis were already developed by then which used 'CIS'. Hence "UNITE" enum has value "CIS".
  // Change to CIS if apis are corrected in future
  // Also update the translation key in "admin-ui/public/hilti/locales/messages_en-US.json" CIS and CIS_ABBREVIATION to use UNITE instead
  UNITE = "CIS",
  RENTAL_SERVICES = "RENTAL_SERVICES",
  PROACTIVE_TRACKING = "PROACTIVE_TRACKING",
}

export enum UserAccessEnum {
  ADMIN_PORTAL = "ADMIN_PORTAL",
  LOCAL_ADMIN_PORTAL = "LOCAL_ADMIN_PORTAL",
}

export enum LicenseTypeEnum {
  OT = "OT",
  FM = "FM",
}

export enum LicenseBundleEnum {
  ONTRACK_READY = "ONTRACK_READY",
  ONTRACK_LITE = "ONTRACK_LITE",
  ONTRACK_PRO = "ONTRACK_PRO",
  ONTRACK_ENTERPRISE = "ONTRACK_ENTERPRISE",
}

export enum LicenseTypeValue {
  OT = "common:ON_TRACK",
  FM = "common:FLEET_MANAGER",
}

export enum FeatureFlagValue {
  ENTERPRISE_TENANT = "tenent.enterprise-tenant",
  UNITE_TEST_TENANT = "tenent.unite-test-tenant",
  RENTAL_SERVICES_MODULE = "tenant.rental-services-module",
  CUSTOMER_TS_LOGIN = "customer.ts-login",
  LICENSE_BUNDLE = "tenant.license-bundle",
  TENANT_SCHEDULED_DELETE = "tenant.scheduled-delete",
}

export enum TenantCategory {
  STANDARD = "STANDARD",
  ENTERPRISE = "ENTERPRISE",
  UNITE_TEST = "UNITE_TEST",
}

export enum Role {
  ADMIN = "ADMIN",
  ENTERPRISE_ADMIN = "ENTERPRISE_ADMIN",
}

export enum RoleValue {
  ADMIN = "Admin",
  SUPPORT_ADMIN = "Support Admin",
  ENTERPRISE_ADMIN = "Enterprise Admin",
}

export enum Onboarding {
  asset = "asset.auto-onboarding",
}
