// @ts-ignore
import { ToastrActions, UserActions } from "custom-actions-admin";
import { call, put, takeLatest } from "redux-saga/effects";
import { showToastr, ToastrType } from "../../actions/toastrAction";
import UserAPI from "../../api/userApi";

/** saga for searching user by email id */
export function* getUserDetailsByEmailId() {
  yield takeLatest(UserActions.UM_GET_USER_BYEMAIL, userDetailsByEmailId);
}

/**
 * saga for making api calls for searching user by email id
 *
 * @param action : action from dispatcher with payload for making api call
 */
export function* userDetailsByEmailId(action) {
  const response = yield call(invokeGetUserDetilsAPI(action));
  return yield handleGetUserDetailResponse(response);
}

/**
 * method to handle response from get user detail api
 *
 * @param response response from api call
 */
export function* handleGetUserDetailResponse(response) {
  if (response && response.error) {
    const error = getErrorFromResponse(response.error);
    yield put({ type: UserActions.PUT_USER_BYEMAIL_FAIL, payload: error });
  } else {
    yield put({ type: UserActions.PUT_USER_BYEMAIL_SUCCESS, payload: response });
  }
}

/**
 * method make api call
 *
 * @param action data to invoke api
 */
export function invokeGetUserDetilsAPI(action) {
  return () => {
    return UserAPI.getUserDetailsByEmailId(action.payload.emailId, action.payload.tenantId);
  };
}

/** saga for updating user role */
export function* promoteUserRole() {
  yield takeLatest(UserActions.UM_UPDATE_USER_ROLE, userRoleUpdate);
}

/**
 * saga for making api calls for updating user role
 *
 * @param action : action from dispatcher with payload for making api call
 */
export function* userRoleUpdate(action) {
  const response = yield call(invokeUpdateUserRoleAPI(action));
  return yield handleUserRoleUpdateResponse(response);
}

export function* handleUserRoleUpdateResponse(response: any) {
  if (response && response.error) {
    const error = getErrorFromResponse(response.error);
    yield put({ type: UserActions.UPDATE_USER_ROLE_FAIL, payload: error });
    yield put({
      payload: { toastrType: ToastrType.error, toastrMessage: `errorCodes:${error && error[0].code}`, translate: true },
      type: ToastrActions.SHOW_TOASTR,
    });
  } else {
    yield put({ type: UserActions.UPDATE_USER_ROLE_SUCCESS, payload: response });
    yield put(showToastr(ToastrType.success, "common:CUSTOMER_ADMIN_PROMOTE_SUCCESS", true));
  }
}

export function invokeUpdateUserRoleAPI(action: any) {
  return () => {
    return UserAPI.promoteUserRole(action.payload);
  };
}

/**
 * method to get error nested in the API error response
 * NOT Placed in utility as we still don't have generic structure on error
 *
 * @param error error received in response from API
 */
export function getErrorFromResponse(error) {
  return error && error.response && error.response.data && error.response.data.errors;
}
