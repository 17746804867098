// @ts-ignore
import { HelperLodash } from "am-web-ui-shared/helpers";
// @ts-ignore
import { TenantActions } from "custom-actions-admin";
import { SortList } from "../../../config";
import { ITenantReducerState } from "../../models/iTenantReducerState";
import { clearAllCheckedRows, clearAllSelection, setCheckboxSelection, setRowSelection } from "../../utils/listUtils";

const selectRow = (state, action) => {
  const selectRowList = state.tenantsList && state.tenantsList.response.slice(0);
  clearAllSelection(selectRowList);
  return {
    ...state,
    tenantsList: { ...state.tenantsList, response: setRowSelection(selectRowList, action.id, true, "tenantId") },
  };
};

const unselectRow = (state, action) => {
  const unSelectedList = state.tenantsList && state.tenantsList.response.slice(0);
  return {
    ...state,
    tenantDetails: null,
    tenantsList: { ...state.tenantsList, response: setRowSelection(unSelectedList, action.id, false, "tenantId") },
  };
};

const handleGridCheckboxSelection = (state, action, selected: boolean) => {
  const selectRowList = HelperLodash.cloneDeep(state.tenantsList && state.tenantsList.response);
  return {
    ...state,
    tenantsList: {
      ...state.tenantsList,
      response: setCheckboxSelection(selectRowList, action.id, selected, "tenantId"),
    },
  };
};
const setTenantSearchQuery = (state, action) => {
  return {
    ...state,
    searchString: action.payload,
  };
};
const putTenants = (state, action) => {
  if (action.payload) {
    const oldResponse = action.payload.offset ? state.tenantsList.response : [];
    action.payload.tenantData.response = [...oldResponse, ...action.payload.tenantData.response];

    return {
      ...state,
      resetScrollPosition: !action.payload.offset,
      showNoRecordFoundView: state.searchString && !action.payload.tenantData.response.response,
      tenantsList: action.payload.tenantData,
    };
  } else {
    return { ...state };
  }
};

/**
 * @description Tenant reducer constants
 */
const sortQuery = {
  sortField: SortList.TENANTS.DEFAULT_SORT.sortField,
  sortType: SortList.TENANTS.DEFAULT_SORT.sortType,
};
const tenantReducer = (
  state: ITenantReducerState = {
    checkedTenantDetails: null,
    isAllSelected: false,
    resetScrollPosition: false,
    searchString: "",
    showNoRecordFoundView: false,
    sortQuery,
    tenantDetails: null,
    tenantsList: null,
  },
  action: { type: any; payload?: any; sortQuery?: string },
) => {
  switch (action.type) {
    case TenantActions.SET_TENANT_SORT_QUERY:
      return {
        ...state,
        sortQuery: action.sortQuery,
      };
    case TenantActions.PUT_TENANTS:
      return putTenants(state, action);

    case TenantActions.SET_TENANT_DETAILS:
      return {
        ...state,
        tenantDetails: action.payload,
      };
    case TenantActions.SET_CHECKED_TENANT_DETAILS:
      return {
        ...state,
        checkedTenantDetails: action.payload,
      };
    case TenantActions.CLOSE_TENANT_DETAILS:
      return {
        ...state,
        tenantDetails: null,
      };
    case TenantActions.TENANT_ACTIVATION_STATUS: {
      const isTenantStatusUpdated = action.payload.isTenantStatusUpdated;

      return {
        ...state,
        isTenantStatusUpdated,
      };
    }
    case TenantActions.TENANT_UNSELECT_ALL_ROWS:
      return clearAllSelectedRows(state);
    case TenantActions.PUT_TENANT_DEFAULT_COUNT:
      return modifyDefaultCount(state, action);
    default:
      return tenantsReducer(state, action);
  }
};

const modifyDefaultCount = (state, action) => {
  const tenantData = HelperLodash.first(action.payload);
  return {
    ...state,
    tenantDetails: {
      ...state.tenantDetails,
      numberOfActiveAssets: tenantData.noOfUniqueAssets,
      totalQtyItemCount: tenantData.totalQtyItemCount,
    },
  };
};

const tenantsReducer = (state, action) => {
  switch (action.type) {
    case TenantActions.UPDATE_TENANT_LIST: {
      return {
        ...state,
        tenantsList: {
          ...state.tenantsList,
        },
      };
    }
    case TenantActions.TENANT_SELECT_ROW:
      return selectRow(state, action);
    case TenantActions.TENANT_DESELECT_ROW:
      return unselectRow(state, action);
    case TenantActions.TENANT_SELECT_GRID_CHECKBOX:
      return handleGridCheckboxSelection(state, action, true);
    case TenantActions.TENANT_UNSELECT_GRID_CHECKBOX:
      return handleGridCheckboxSelection(state, action, false);
    case TenantActions.SET_TENANT_SEARCH_QUERY:
      return setTenantSearchQuery(state, action);
    case TenantActions.SET_TENANT_SORT_QUERY:
      return {
        ...state,
        sortQuery: action.sortQuery || sortQuery,
      };
    default:
      return state;
  }
};

/**
 * @description - To clear the selection of tenants list, setting the "isSelected" value false.
 * @param {any } state  - Tenant state
 */
const clearAllSelectedRows = (state) => {
  return {
    ...state,
    isAllSelected: false,
    tenantsList: {
      ...state.tenantsList,
      response: clearAllCheckedRows(state.tenantsList && state.tenantsList.response),
    },
  };
};
export default tenantReducer;
