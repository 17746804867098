import { colorPalette } from "am-web-ui-shared/materials";
import { createTheme } from "@mui/material/styles";
import { theme as hiltiTheme } from "@hilti/components";

const colors = {
  base: {
    black: colorPalette.black,
    black10: colorPalette.black10,
    blackrgba: colorPalette.blackrgba,
    blackrgba20: colorPalette.blackrgba20,
    borderColor: colorPalette.heavyConcrete20,
    green: colorPalette.green,
    greyrgba: colorPalette.greyrgba,
    heavyConcrete: colorPalette.heavyConcrete,
    heavyConcrete10: colorPalette.heavyConcrete10,
    heavyConcrete20: colorPalette.heavyConcrete20,
    heavyConcrete40: colorPalette.heavyConcrete40,
    heavyConcrete60: colorPalette.heavyConcrete60,
    heavyConcreteAlpha: colorPalette.heavyConcreteAlpha,
    hiltiOffWhite: colorPalette.offWhite,
    hiltiRed: colorPalette.hiltiRed,
    hiltiRedError: colorPalette.hiltiRedError,
    lightGreyColor: colorPalette.lightGreyColor,
    lightRedColor: colorPalette.lightRedColor,
    linkColor: colorPalette.steel,
    orange: colorPalette.orange,
    pink: colorPalette.pink,
    placeholderColor: colorPalette.placeholderColor,
    steel: colorPalette.steel,
    steel10: colorPalette.steel10,
    steel20: colorPalette.steel20,
    steel40: colorPalette.steel40,
    titleColor: colorPalette.titleColor,
    transparent: colorPalette.transparent,
    white: colorPalette.white,
    whiteFaded: colorPalette.whiteFaded,
  },
  divBorder: {
    default: colorPalette.heavyConcrete20,
    backgroundLightGrey: colorPalette.customLightGrey,
    darkGrey: colorPalette.customDarkGrey,
  },
  headingText: {
    color: "#000000",
  },
  innerHeadingText: {
    color: "#524F53",
  },
};

const customTheme = {
  boldBlackText: {
    color: colorPalette.black,
  },
  boldSteelText: {
    color: colorPalette.steel,
  },
  buttonStyle: {
    cta: {
      backgroundColor: colorPalette.hiltiRed,
      backgroundHoverColor: colorPalette.hiltiRedHover,
      borderColor: colorPalette.hiltiRed,
      color: colorPalette.white,
    },
    disabled: {
      backgroundColor: colorPalette.steel20,
      color: colorPalette.white,
    },
    primary: {
      backgroundColor: colorPalette.steel,
      backgroundHoverColor: colorPalette.black,
      borderColor: colorPalette.steel,
      color: colorPalette.white,
    },
    secondary: {
      backgroundColor: colorPalette.heavyConcrete20,
      backgroundHoverColor: colorPalette.heavyConcrete40,
      borderColor: colorPalette.heavyConcrete60,
      color: colorPalette.steel,
    },
  },

  detailsComponents: {
    assetStatus: {
      broken: {
        backgroundColor: colorPalette.warningSecondaryYellow,
        borderColor: "#e1af00",
      },
      default: {
        backgroundColor: "#fff",
        borderColor: "#887f6e",
      },
      serviceExpired: {
        backgroundColor: "#fcc",
        borderColor: "#f00",
      },
    },
    tenants: {
      badgeBackground: "#D8F4DF",
      badgeText: "#117A26",
      componentBorder: colorPalette.heavyConcrete20,
      innerText: "#524F53",
      scrollBar: {
        background: "F5F5F5",
      },
      wrapperText: "rgba(82,79,83,0.800000011920929)",
    },
  },
  detailsPanel: {
    detailLabelColor: "#524F53",
    detailTextColor: "#524F53",
    detailsSectionBackground: "#fafafa",
    summaryLabelColor: "#524F53",
    summaryTextColor: "#524F53",
    titleColor: colorPalette.titleColor,
    titleInnerColor: colorPalette.black90,
  },
  fontBold: {
    fontFamily: `"Hilti-Bold", Arial,Verdana,sans-serif`,
    fontWeight: 700,
  },
  fontNormal: {
    fontFamily: `"Hilti-Roman", Arial,Verdana,sans-serif`,
    fontWeight: 400,
  },
  fontRoman: {
    fontFamily: `"Hilti-Roman", Arial,Verdana,sans-serif`,
    fontWeight: 400,
  },
  fontSize: {
    base: "14px",
    extraLarge: "20px",
    extraSmall: "10px",
    large: "18px",
    medium: "16px",
    small: "12px",
  },
  gridBackground: {
    color: colorPalette.heavyConcrete20,
  },
  header: {
    color: "#191919",
  },
  img: {
    activeModule: "activeModule.svg",
    add: "add.svg",
    emptyAsset: "",
  },
  normalRedText: {
    color: colorPalette.hiltiRed,
  },
  normalSteelText: {
    color: colorPalette.steel,
  },
  normalUnSelectedText: {
    color: colorPalette.customTarawera,
  },
  recentlyUsedTempaltes: {
    color: colorPalette.black80,
  },
  selectCategoryContainer: {
    color: colorPalette.heavyConcrete10,
  },
  sort: {
    selected: colorPalette.heavyConcrete10,
    text: colorPalette.steel,
  },
  squareImage: {
    borderColor: colorPalette.heavyConcrete20,
  },
  successTitle: {
    backgroundColor: colorPalette.white,
    borderColor: colorPalette.heavyConcrete20,
    hyperLinkColor: colorPalette.steel,
    spanIcon: colorPalette.heavyConcrete10,
    textColor: colorPalette.black,
  },
  tenantList: {
    tenantTableColors: {
      blackBackground: colorPalette.black,
      blackTextColor: colorPalette.black,
      cyanBackground: "#888",
      darkGreyColor: colorPalette.black90,
      greyBackground: colorPalette.heavyConcrete10,
      greyDarkBackground: colorPalette.greyDark,
      lightBlackColor: colorPalette.grey,
      lightGreyColor: colorPalette.lightGreyColor,
      lightRedColor: "#C30000",
      mediumDarkGrey: "#555",
      pinkColor: "#ffd7d8",
      redTextColor: "#c8051e",
      steelTextColor: colorPalette.steel,
      whiteBackground: colorPalette.white,
      whiteSmokeBackground: colorPalette.whiteSmoke,
    },
  },
  tenantStatusModal: {
    backgroundColor: "rgba(0, 0, 0, 0.6000000238418579)",
    confirmationModal: {
      boxShadowColor: "rgba(0, 0, 0, 0.20000000298023224)",
    },
    notificationModal: {
      boxShadowColor: "rgba(82, 79, 83, 0.5)",
      insetColor: colorPalette.heavyConcrete,
    },
  },
  tenantToolBar: {
    textColor: colorPalette.steel,
  },
  viewCategoryCard: {
    assetDescriptionColor: colorPalette.steel,
    assetHeadingColor: colorPalette.steel,
    backgroundColor: colorPalette.white,
    borderColor: colorPalette.heavyConcrete20,
  },
  ...hiltiTheme,
  colors: {
    ...hiltiTheme.colors,
    ...colors,
  },
};

const theme = createTheme(customTheme as any);
export default theme;
