// @ts-ignore
import { UserProfileConstants } from "custom-actions-admin";

import { put, takeLatest } from "redux-saga/effects";
import { call } from "../common/commonSaga";

import { showToastr, ToastrType } from "../../actions/toastrAction";
import { getUserMe } from "../../api/userProfileApi";

/** saga for userMe */

export function* getUserMeDetailsSaga() {
  yield takeLatest(UserProfileConstants.GET_USER_ME, fetchUserMe);
}

/**
 * saga for making api calls for updating user role
 *
 * @param action : action from dispatcher with payload for making api call
 */
function* fetchUserMe() {
  const response = yield call(getUserMe);
  return yield handleUserMeResponse(response);
}

function* handleUserMeResponse(response) {
  if (response && response.error) {
    const error = response.error.response.data.error.code;
    if (error !== "401") {
      yield put(showToastr(ToastrType.error, `errorCodes:${error}`, true));
    }
  } else {
    yield put({ type: UserProfileConstants.PUT_USER_ME_SUCCESS, payload: response });
  }
}
