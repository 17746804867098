import toastrActions from "../../actionConstants/toastrConstant";

const defaultState = {
  showNotification: false,
  toastrMessage: "",
  toastrType: "",
};

/**
 * @description toastr reducer constants
 *
 * @param state : current state of store
 * @param action : action for which reducer will reduce
 */
const toastrReducer = (state = defaultState, action) => {
  switch (action.type) {
    case toastrActions.SHOW_TOASTR:
      return {
        showNotification: true,
        toastrMessage: action.payload.toastrMessage,
        toastrType: action.payload.toastrType,
        translate: action.payload.translate,
      };
    case toastrActions.CLOSE_TOASTR:
      return {
        showNotification: false,
        toastrMessage: "",
        toastrType: "",
      };
    default:
      return state;
  }
};

export default toastrReducer;
