/**
 * @description Action constants to be used for common module and its feature
 *
 * format: {feature}_{action}
 */
const LoginActions = {
  GET_LOGIN_MARKET_ORGANIZATIONS: "GET_LOGIN_MARKET_ORGANIZATIONS",
  GET_MARKET_ORGANIZATIONS_SUCCESS: "GET_MARKET_ORGANIZATIONS_SUCCESS",
  LOGGED_IN_MARKET_ORG: "LOGGED_IN_MARKET_ORG",
};

export default LoginActions;
