import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
/**
 * @description - List of namespaces that are associalted with the localization and this will be same as the file name e.g. "assets.json"
 */

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: () => {
        return "/hilti/locales/messages_{{lng}}.json";
      },
    },
    fallbackLng: "en-US",
    keySeparator: ":",
    load: "currentOnly",
    react: {
      useSuspense: false,
    },
    returnEmptyString: false,
  });

export default i18n;
