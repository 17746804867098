import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserMe } from "../../../actions/userProfileAction";
import { storageClearAll, storageGetItem } from "../../../utils/storageUtils";
import { logout } from "../actions/logoutActionRoot";
import IHeaderProps from "../iHeaderProps";
import Header from "../presentational/header";

class AdminHeader extends React.Component<IHeaderProps> {
  constructor(props) {
    super(props);
  }
  logoutAdmin = () => {
    this.props.logoutAdmin();
    storageClearAll();
  };

  componentDidMount() {
    if (storageGetItem("adminToken")) {
      this.props.getUser();
    }
  }
  render() {
    return (
      <Header
        loginMarketOrg={this.props.loginMarketOrg}
        logoutAdmin={this.logoutAdmin}
        userProfileSelfSuccess={this.props.userProfileSelfSuccess}
      />
    );
  }
}
export const mapStateToProps = (state) => ({
  loginMarketOrg: state.Login && state.Login.loggedInMarketOrg,
  userProfileSelfSuccess: state.userMe && state.userMe.userMeData,
});

export const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUserMe()),
  logoutAdmin: () => dispatch(logout()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminHeader));
