import { styled } from '@mui/material/styles';
import Chip, { chipClasses } from '@mui/material/Chip';

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: 23,
  borderRadius: theme.shape.borderRadius,
  [`& .${chipClasses.label}`]: {
    paddingLeft: 8,
    paddingRight: 8,
  },
  [`& .${chipClasses.outlined}`]: {
    border: 0,
  },
}));
