/**
 * @description This file is for api hitting using
 * Gateway for the create Admin web UI user.
 */
import Gateway from "../../common/gateway/gateway";
import { getUserMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description API for handling Customer Admin
 * @memberof CustomerAdmin Module
 */
export default class UserAPI {
  static errorObj = { error: "Unknown error" };
  /**
   * @description Returns the respose of User details from API
   *
   * @static
   * @memberof UserAPI
   * @param {} : empty object
   */
  static getUserDetailsByEmailId = async (email: string, tenantId: number): Promise<any> => {
    try {
      const url = getUserMgmtEndpoint(`/tenants/${tenantId}/user/email`);
      const additionalHeaders = { email };
      return Gateway.get(url, null, additionalHeaders);
    } catch (error) {
      return UserAPI.errorObj;
    }
  };

  /**
   * @description Returns the respose of Update user role from API
   *
   * @static
   * @memberof UserAPI
   * @param {} : empty object
   */
  static promoteUserRole = async (payload: any): Promise<any> => {
    try {
      const url = getUserMgmtEndpoint(`/admin/tenants/${payload.tenantId}/employees/${payload.employeeId}/promote`);
      return Gateway.patch(
        url,
        null,
        {
          contactId: payload.contactId,
          isExtendAccess: payload.isExtendAccess,
          jobFunctionId: payload.jobFunctionId,
          userRole: payload.userRole,
          extEmployeeId: payload.extEmployeeId,
          originContactId: payload.originContactId,
        },
        null,
      );
    } catch (error) {
      return UserAPI.errorObj;
    }
  };
}
