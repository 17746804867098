//@ts-ignore
import { API_TIMEOUT, appConstants } from "ADMIN_TARGET_BUILD/config";
import axios from "axios";
import { IAPIResponse } from "../../common/models/iApiResponse";
import ErrorCodes from "../../common/utils/enums/errorCodes";
import { IErrorResponse } from "../models/iApiError";
import { storageClearAll, storageClearItem, storageGetItem } from "../utils/storageUtils";
import { getCustomerToken } from "../modules/customerAuthCallback/hiltiTokenStore";
import { shouldUseCustomerToken } from "../modules/customerAuthCallback/authHelper";
/**
 * This class can be used to make API calls to external servers by providing appropriate parametrs
 */
class Gateway {
  /**
   * It is the main method making calls to API
   *
   * @ method: HTTP verb GET, PUT, POST, DELETE
   * @url: API Url to make call on
   * @params: any query parameters need to be added in call
   * @body: body for the request
   * @additionalHeaders: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static async call(
    method,
    url,
    params,
    body,
    additionalHeaders,
    blob,
    shouldAuthenticated = true,
  ): Promise<IAPIResponse | IErrorResponse> {
    const requestBody = body;
    const adminToken = storageGetItem(appConstants.ADMIN_TOKEN);

    if (!Gateway.validateRequest(shouldAuthenticated, adminToken)) {
      storageClearAll();
      storageClearItem(appConstants.ADMIN_TOKEN);
      window.location.assign("/login");
      return { error: "NO_TOKEN", response: "" };
    }

    const headers = {
      ...additionalHeaders,
      "Content-Type": "application/json",
    };

    if (shouldAuthenticated) {
      const useCustomerToken = shouldUseCustomerToken(method, url, requestBody);
      if (useCustomerToken) {
        const token = getCustomerToken() || adminToken;
        headers.Authorization = `Bearer ${token}`;
      } else {
        headers.Authorization = `Bearer ${adminToken}`;
      }
    }

    return axios({
      data: requestBody,
      headers,
      method,
      params,
      responseType: blob,
      timeout: API_TIMEOUT,
      url,
    })
      .then((response) => Gateway.handlePromiseResolve(response))
      .catch((error) => Gateway.handlePromiseReject(error));
  }

  /***
   * Handler for promise resolve
   */
  static handlePromiseResolve = (response) => {
    if (
      response.status === ErrorCodes.E200 ||
      response.status === ErrorCodes.E201 ||
      response.status === ErrorCodes.E204
    ) {
      return response.data;
    }
    return false;
  };

  /**
   *  Handler for promise reject
   */
  static handlePromiseReject = (error) => {
    const response = error && error.response;
    /*
    Its a temporary solution for redirection for unauthorized calls.
    */
    if (response && response.status === ErrorCodes.E401) {
      storageClearAll();
      storageClearItem(appConstants.ADMIN_TOKEN);
      window.location.assign("/login");
    }
    return { error };
  };

  /**
   * Use it to make get calls
   *
   * @url: API Url to make call on
   * @body: body for the request
   * @header: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static get = (url, params = null, header = null, responseType = null): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.call("GET", url, params, null, header, responseType);
  };

  /**
   * Use it to make get calls
   *
   * @url: API Url to make call on
   * @body: body for the request
   * @header: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static getUnAuthorized = (url: string, params = null, header = null): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.call("GET", url, params, null, header, null, false);
  };

  /**
   * Use it to make post calls
   *
   * @url: API Url to make call on
   * @params: any query parameters need to be added in call
   * @body: body for the request
   * @header: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static post = (url, params = null, body = null, header = null): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.call("POST", url, params, body, header, {});
  };
  /**
   * Use it to make put calls
   *
   * @url: API Url to make call on
   * @params: any query parameters need to be added in call
   * @body: body for the request
   * @header: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static put = (url, params, body, header): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.call("PUT", url, params, body, header, {});
  };
  /**
   * Use it to make delete calls
   *
   * @url: API Url to make call on
   * @params: any query parameters need to be added in call
   * @header: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static delete = (url, params, header): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.call("DELETE", url, params, null, header, {});
  };

  static validateRequest = (shouldAuthenticated, token) => {
    if (process.env.NODE_ENV === "development") {
      return true;
    }

    if (shouldAuthenticated && token) {
      return true;
    }
    if (!shouldAuthenticated) {
      return true;
    }
    return false;
  };

  /**
   * Use it to make patch calls
   *
   * @url: API Url to make call on
   * @params: any query parameters need to be added in call
   * @header: information to share in header
   *
   * return IAPIResponse : If call is success else IErrorResponse
   */
  static patch = (url, params, body, header): Promise<IAPIResponse | IErrorResponse> => {
    return Gateway.call("PATCH", url, params, body, header, {});
  };
}

export default Gateway;
