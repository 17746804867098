/**
 * @description This file is for api hitting using
 * Gateway for the create Admin web UI user.
 */
import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import User from "../models/user";
import UserRoles from "../models/userRoles";
import { ICustomerAdminResponse } from "../modules/customerAdmin/customerAdminResponse";
import { getUserMgmtEndpoint } from "../utils/apiUtils";
import { TenantCategory } from "../utils/enums/tenantEnum";
import EnterpriseRoles from "../models/enterpriseRoles";

const errorObj = {
  error: "Unknown error",
};
const ORIGIN_ONTRACK = "ON!Track";
/**
 * @description API for handling Customer Admin
 * @memberof CustomerAdmin Module
 */
export default class CustomerAdminAPI {
  /**
   * @description Returns the respose of Add tenant from API
   *
   * @static
   * @memberof CustomerAdminAPI
   * @param {} : empty object
   * @header adminUser : tenant/customer  admin user added data in the form
   */
  static createCustomerAdmin = async (adminUser: User, tenantCategory: string): Promise<any> => {
    const isTechnicalUser =
      adminUser.userRole === UserRoles.TECHNICAL_USER || adminUser.userRole === UserRoles.TECHNICAL_USER_LOCKER;

    const user = isTechnicalUser
      ? {
          prefix: adminUser.prefix,
          allowedIPs: adminUser.allowedIPs,
          role: adminUser.userRole,
        }
      : {
          ...adminUser,
          isExtendAccess: adminUser.userRole === UserRoles.ADMIN && tenantCategory === TenantCategory.STANDARD,
          origin: ORIGIN_ONTRACK,
        };

    try {
      const params = {};
      const url = getUserMgmtEndpoint(
        `/tenants/${adminUser.tenantId}/${isTechnicalUser ? "technical-user" : "worker-users"}/`,
      );
      return Gateway.post(url, params, user, null);
    } catch (error) {
      return errorObj;
    }
  };
  static editSupportAdmin = async (adminUser, adminId): Promise<any> => {
    adminUser = {
      expiryDate: adminUser.expiryDate,
      tenantId: adminUser.tenantId,
      userRole: adminUser.userRole,
    };
    try {
      const params = {};
      const url = getUserMgmtEndpoint(`/admin/tenants/${adminUser.tenantId}/employees/${adminId}/expiry-date`);
      return Gateway.put(url, params, adminUser, null);
    } catch (error) {
      return errorObj;
    }
  };
}

/**
 * Returns list of Admins corresponding to the tenant.
 * @param tenantId : Selected tenantId.
 */
export const getCustomerAdmins = async (
  tenantId,
  tenantCategory?,
): Promise<ICustomerAdminResponse | IErrorResponse> => {
  try {
    const roles = [];
    if (tenantCategory === TenantCategory.ENTERPRISE) {
      roles.push(EnterpriseRoles.SUPPORT_ADMIN);
      roles.push(EnterpriseRoles.ENTERPRISE_ADMIN);
    } else {
      roles.push(UserRoles.ADMIN);
      roles.push(UserRoles.SUPPORT_ADMIN);
      roles.push(UserRoles.TECHNICAL_USER);
      roles.push(UserRoles.TECHNICAL_USER_LOCKER);
    }
    const url = getUserMgmtEndpoint(`/tenants/${tenantId}/users/roles?userRole=${roles.join(",")}`);

    return Gateway.get(url, null, null) as unknown as ICustomerAdminResponse;
  } catch (error) {
    return errorObj as unknown as IErrorResponse;
  }
};

/**
 * Returns list of Admins corresponding to the tenant.
 * @param tenantId : Selected tenantId.
 */
export const getAdminDetails = async (tenantId, emailId): Promise<any | IErrorResponse> => {
  try {
    const url = getUserMgmtEndpoint(`/tenants/${tenantId}/user/email`);
    const additionalHeaders = { email: emailId };
    return Gateway.get(url, null, additionalHeaders) as unknown as any; // To-do
  } catch (error) {
    return errorObj as unknown as IErrorResponse;
  }
};
