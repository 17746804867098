// @ts-ignore
import { appConstants } from "ADMIN_TARGET_BUILD/config";
import { Dropdown, IconName, OutsideClickAlerter, Prompt, SquareIcon } from "am-web-ui-shared/components";
import React from "react";
import { withTranslation } from "react-i18next";
import { getFullName } from "../../../../common/utils/apiUtils";
import { storageClearAll, storageGetItem } from "../../../../common/utils/storageUtils";
import StyleAttrConstants from "../../../styleAttrConstants";
import styled from "../../../themeConfig/styledComponents";
import IUserProfileProps from "./iUserProfile";
const Ellip = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledName = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 8px;
  flex: 1 1 0;
`;

export const StyledAuthor = styled(Ellip as any)`
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
  margin-bottom: 6px;
`;

export const StyledUserProfile = styled("div").attrs({
  id: "userSetting",
})`
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 24px;
  border-left: 2px solid ${(props) => props.theme.colors.base.borderColor};
  cursor: pointer;
  min-width: 250px;
  max-width: 275px;
  height: 100%;
`;

const UnorderedList = styled.ul.attrs({
  id: "userSettingDropDown",
})``;

export const ListStyle = styled.li`
  list-style: none;
  display: flex;
  min-height: 48px;
`;

export const ListLink = styled.a`
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.black};
  font-family: ${(props) => props.theme.fontRoman.fontFamily};
  line-height: 19px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.colors.base.heavyConcrete10};
    font-family: ${(props) => props.theme.fontBold.fontFamily};
    font-weight: ${(props) => props.theme.fontBold.fontWeight};
  }
`;

const Label = styled.span`
  margin-left: 8px;
`;

export class UserProfile extends React.PureComponent<IUserProfileProps> {
  state = {
    isOpen: false,
    showLogOutConfirm: false,
  };

  handleProfileClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  /**
   * @description Function tologout by calling IDM API directly
   */
  logoutAdmin = () => {
    this.props.logoutAdmin();
    storageClearAll();
  };

  handleLogOutPrompt = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      showLogOutConfirm: !this.state.showLogOutConfirm,
    });
  };

  renderSettingsMenu = () => {
    const { t } = this.props;

    return storageGetItem(appConstants.ADMIN_TOKEN) ? (
      <UnorderedList>
        <ListStyle>
          <ListLink data-testid="logoutButton" id="logoutButton" onClick={this.handleLogOutPrompt}>
            <SquareIcon id="logoutIcon" name={IconName.Logout} />
            <Label>{t("common:LOGOUT")}</Label>
          </ListLink>
        </ListStyle>
      </UnorderedList>
    ) : null;
  };

  userMe = (userProfileSelfSuccess) => {
    let getDetails;
    if (userProfileSelfSuccess.firstName || userProfileSelfSuccess.lastName) {
      getDetails = getFullName(userProfileSelfSuccess.firstName, userProfileSelfSuccess.lastName);
    } else {
      getDetails = userProfileSelfSuccess.email;
    }
    return getDetails;
  };

  render() {
    const { name, userProfileSelfSuccess, t } = this.props;

    return (
      <>
        <OutsideClickAlerter
          render={() => (
            <StyledUserProfile onClick={this.handleProfileClick} data-testid="userSetting">
              <SquareIcon id="userProfileImage" name={name} />
              <StyledName id="userFullName" title={userProfileSelfSuccess && this.userMe(userProfileSelfSuccess)}>
                <StyledAuthor>{userProfileSelfSuccess && this.userMe(userProfileSelfSuccess)}</StyledAuthor>
              </StyledName>
              {this.state.isOpen ? (
                <Dropdown
                  dropDownPosition={{ top: `${StyleAttrConstants.TOP.T48}px` }}
                  renderAsProps={this.renderSettingsMenu}
                />
              ) : null}
            </StyledUserProfile>
          )}
          onClickAway={() => this.setState({ isOpen: false })}
        />
        {this.state.showLogOutConfirm && (
          <Prompt
            icon={IconName.Info}
            handleClose={this.handleLogOutPrompt}
            handleConfirm={this.logoutAdmin}
            header={t("common:LOGOUT_CONFIRM_MESSAGE")}
            cancelLabel={t("common:CANCEL")}
            okLabel={t("common:OK")}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UserProfile);
