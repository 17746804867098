import { getfeatureFlagsData } from "./featureFlagsApi";

interface IfeatureFlags {
  key: string;
  active: boolean;
}
class SingletonFeatureManager {
  private static instance: SingletonFeatureManager;
  private static featureFlags: IfeatureFlags[] = [];
  private constructor() {
    // ...
  }

  private static async fetchFeatureFlags(product: string, userId: number) {
    try {
      const response = await getfeatureFlagsData(product, userId);
      const { result } = response;
      SingletonFeatureManager.featureFlags = [...result];
    } catch (error) {
      SingletonFeatureManager.featureFlags = [];
    }
  }

  public static async initFeatures(product: string, userId: number) {
    try {
      if (!SingletonFeatureManager.instance) {
        SingletonFeatureManager.instance = new SingletonFeatureManager();
        //Fetch feature flags
        SingletonFeatureManager.fetchFeatureFlags(product, userId);
      }
    } catch (error) {
      SingletonFeatureManager.featureFlags = [];
    }
    return SingletonFeatureManager.instance;
  }
  public static isFeatureEnabled(featureKey: string) {
    if (featureKey) {
      const index = SingletonFeatureManager.featureFlags.findIndex((e) => e.key === featureKey);
      if (index !== -1) {
        return SingletonFeatureManager.featureFlags[index].active;
      }
    }
    return true;
  }
  // ...
}

export default SingletonFeatureManager;
