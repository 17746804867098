// @ts-ignore
import { TenantActions } from "custom-actions-admin";

const initialState = { isEdited: false, errorMessage: "" } as any;
/**
 * @description edit tenant reducer
 */
export const editTenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case TenantActions.EDIT_TENANT_DETAILS_SUCCESS:
      return {
        ...state,
        errorMessage: "",
        isEdited: action.payload,
      };
    case TenantActions.EDIT_TENANT_DETAILS_FAIL:
    case TenantActions.EDIT_TENANT_CLEAR_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
