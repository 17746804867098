import { TokenResponse } from "@openid/appauth";
import { TokenResponseJson } from "@openid/appauth/src/token_response";
import SubscriptionInfo from "./subscriptionInfo";

export interface HiltiTokenJson extends TokenResponseJson {
  subscription_info?: SubscriptionInfo;
}

class HiltiToken extends TokenResponse {
  subscriptionInfo: SubscriptionInfo | undefined;

  constructor(json: HiltiTokenJson) {
    super(json);
    this.subscriptionInfo = json.subscription_info;
  }

  toJson(): HiltiTokenJson {
    const tokenResponseJson: HiltiTokenJson = super.toJson();
    tokenResponseJson.subscription_info = this.subscriptionInfo;
    return tokenResponseJson;
  }
}

export default HiltiToken;
