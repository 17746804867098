import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { ITenant } from "../models/iTenant";
import { TenantActivationStatusWithId } from "../models/tenantActivationStatusIdModel";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getOrgMgmtEndpoint } from "../utils/apiUtils";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
import { ILicenseBundleDetails } from "../models/iApiResponse";

/**
 * GET list of tenants request
 * @return {Promise}
 */
export const getTenants = async (querysObj, filters?): Promise<ITenant[] | {}> => {
  if (filters) {
    return Gateway.get(getOrgMgmtEndpoint(`/tenants${filters}`)) as unknown as ITenant[];
  }

  return Gateway.get(getOrgMgmtEndpoint("/tenants"), { ...querysObj }, null) as unknown as ITenant[];
};

/**
 * GET detail of a tenant
 * @param  {String} tenantId
 * @return {Promise}
 */
export const getTenant = async (tenantId: string, querysObj): Promise<ITenant | {}> => {
  return Gateway.get(getOrgMgmtEndpoint(`/tenants/${tenantId}`), { ...querysObj }, null) as unknown as ITenant;
};

/**
 * PUT request to update tenant activation status
 * @param  {TenantActivationStatusWithId} tenantToUpdate
 * @return {Promise}
 */
export const updateTenantActivationStatus = async (
  tenantToUpdate: TenantActivationStatusWithId,
): Promise<number | IErrorResponse> => {
  return Gateway.put(
    getOrgMgmtEndpoint(`/tenants/${tenantToUpdate.id}/status`),
    null,
    tenantToUpdate.tenantActivationModel,
    null,
  ) as unknown as number;
};
/**
 * Returns all tenant list in byte array format
 */
export const downloadTenantList = async (params: any): Promise<any> => {
  const url = getOrgMgmtEndpoint("/tenants/export");
  return Gateway.get(url, params, null, "blob");
};

/**
 * Sap Link Hilti Non Connected Assets
 */
export const sapLink = async (id: number): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/linkTools/${id}`);
  return Gateway.get(url, null, null) as unknown;
};
/**
 * Sap Resync Hilti Non Connected Assets
 */
export const sapResync = async (id: number): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.HILTI_INTEGRATION, `/resyncToolsData/${id}`);
  return Gateway.get(url, null, null) as unknown;
};

export const getExportIdOfRussianWorkers = async (): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.USER_MGMT, `/russian-workers/export`);
  return Gateway.post(url) as unknown;
};

export const getDocIdOfRussianWorkers = async (exportId: number): Promise<any> => {
  const url = getVersionedApiUrl(ServiceUrl.USER_MGMT, `/russian-workers/export/${exportId}`);
  return Gateway.get(url) as unknown;
};

export const getAssetCountAPI = async (params: { entity: string; ids: number[] }) => {
  const { entity, ids } = params;
  const url = getVersionedApiUrl(ServiceUrl.ASSET_MGMT, `/count/tenants?entity=${entity}`);
  return Gateway.post(url, null, { ids }, null);
};

export const getLicenseBundlesResponse = async (tenantCategory: string) => {
  const url = getVersionedApiUrl(ServiceUrl.ORG_MGMT, "/license-bundle-details");
  const params = { tenantCategory };
  return Gateway.get(url, params, null, null) as unknown as ILicenseBundleDetails;
};
