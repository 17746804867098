// @ts-ignore
import { appConstants } from "ADMIN_TARGET_BUILD/config";
// @ts-ignore
import { LoginActions } from "custom-actions-admin";

import { storageGetItem } from "../utils/storageUtils";
export interface ILoginAction {
  type: string;
  payload: string;
  isLoggedIn: boolean;
}

function getMarketOrg(storedData) {
  return storedData ? storedData : JSON.parse(storageGetItem(appConstants.LOGGED_IN_MARKET_ORG));
}

export const loginReducer = (state = {}, action?: ILoginAction) => {
  switch (action.type) {
    case LoginActions.GET_MARKET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loginMarketOrg: action.payload,
      };

    case LoginActions.LOGGED_IN_MARKET_ORG:
      return {
        ...state,
        loggedInMarketOrg: getMarketOrg(action.payload),
      };

    case "AUTH_REQUEST":
      return {
        ...state,
        payload: action.payload,
      };
    case "AUTH_RESULT":
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    default:
      return state;
  }
};
