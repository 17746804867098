import React, {
  PropsWithChildren,
  HTMLAttributes,
  forwardRef,
  isValidElement,
  Children,
} from 'react';

import Badge from './Badge';
import { StyledList } from './BadgeList.styled';

type Props = {
  maxItems?: number;
};

type NativeAttrs = Omit<HTMLAttributes<unknown>, keyof Props>;

export type BadgeListProps = Props & NativeAttrs;

const defaultProps: Partial<BadgeListProps> = {};

const BadgeList = forwardRef<HTMLDivElement, PropsWithChildren<BadgeListProps>>(
  ({ maxItems: _maxItems, children, ...props }, ref) => {
    const allItems = Children.toArray(children).filter((child) => isValidElement(child));

    const maxItems = _maxItems ?? allItems.length;

    const badges = allItems.slice(0, maxItems);

    const count = allItems.length - maxItems;

    const hasCount = count > 0;

    return (
      <StyledList ref={ref} {...props}>
        {badges}
        {hasCount && <Badge label={`+${count}`} variant={'outlined'} />}
      </StyledList>
    );
  },
);

BadgeList.defaultProps = defaultProps;

export default BadgeList;
