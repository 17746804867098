import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { storeLoggedInMarketDetails } from "./common/actions/loginAction";
import { getUserSetting } from "./common/actions/userSettingActionRoot";
import { IApp } from "./iApp";
import Root from "./root";
import theme from "./theme";
import SingletonFeatureManager from "./featureFlagManager/featureFlagManager";
import { ThemeProvider as MUIThemeProvider } from "@mui/styles";
class App extends React.Component<IApp, { isFeaturesInit: boolean }> {
  constructor(props) {
    super(props);
    this.state = { isFeaturesInit: false };
  }
  componentDidMount() {
    this.props.getUserSetting();
    this.props.setLoggedInMarketOrgDetails();
  }

  componentDidUpdate() {
    if (this.props.userId && !this.state.isFeaturesInit) {
      SingletonFeatureManager.initFeatures("admin-portal", this.props.userId)
        .then(() => {
          this.setState({ isFeaturesInit: true }); // to trigger re-render
        })
        .catch((_error) => {
          //handle error
        });
    }
  }

  render() {
    return (
      <Router>
        <MUIThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Root currentUserRole={this.props.currentUserRole} />
          </ThemeProvider>
        </MUIThemeProvider>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserRole: state.userMe?.userMeData?.userRole,
  userId: state.userMe?.userMeData?.id,
});

const mapDispatchToProps = (dispatch) => ({
  getUserSetting: () => dispatch(getUserSetting()),
  setLoggedInMarketOrgDetails: () => dispatch(storeLoggedInMarketDetails()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
