import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { createTenantReducer } from "./createTenantReducer";
import customerAdminReducer from "./customerAdmin/customerAdminReducer";
import { editTenantReducer } from "./editTenantReducer";
import { loginReducer } from "./loginReducer";
import { masterDataReducer } from "./masterData/masterDataReducer";
import tenantLoaderReducer from "./tenant/tenantLoaderReducer";
import tenantReducer from "./tenant/tenantReducer";
import toastrReducer from "./toastr/toastrReducer";
import { userMeReducer } from "./userProfileReducer";
import usersReducer from "./users/usersReducer";
import adminUsersReducer from "./adminUsers/adminUsersReducer";
import licenseBundleReducer from "./tenant/licenseBundleReducer";

export const commonRootReducer = combineReducers({
  Login: loginReducer,
  MasterData: masterDataReducer,
  Tenant: tenantReducer,
  createTenant: createTenantReducer,
  customerAdmin: customerAdminReducer,
  editTenant: editTenantReducer,
  form: reduxFormReducer,
  tenantLoader: tenantLoaderReducer,
  toastr: toastrReducer,
  user: usersReducer,
  userMe: userMeReducer,
  adminUsers: adminUsersReducer,
  licenseBundle: licenseBundleReducer,
});
export default commonRootReducer;
