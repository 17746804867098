/**
 * @description Action constants to be used across the project in admin-ui when creating a Tenant Admin User
 *
 * format: {feature}_{action}
 */
const CustomerAdminActions = {
  CLEAR_CUSTOMER_ADMINS_LIST: "CLEAR_CUSTOMER_ADMINS_LIST",
  // action to get customer admin response
  CUSTOMER_ADMINS_RESPONSE: "CUSTOMER_ADMINS_RESPONSE",
  // action to get customer admin list for a particular customer
  GET_CUSTOMER_ADMINS_LIST: "GET_CUSTOMER_ADMINS_LIST",
  // action to get handle customer admin
  SELECT_CUSTOMER_ADMIN_ROW: "SELECT_CUSTOMER_ADMIN_ROW",
  UM_ADMIN_DETAILS_RESPONSE: "UM_ADMIN_DETAILS_RESPONSE",
  UM_CLEAR_CREATE_CUSTOMER_ADMIN_RESPONSE: "UM_CLEAR_CREATE_CUSTOMER_ADMIN_RESPONSE",
  UM_CREATE_CUSTOMER_ADMIN: "UM_CREATE_CUSTOMER_ADMIN",
  UM_CREATE_CUSTOMER_ADMIN_FAIL: "UM_CREATE_CUSTOMER_ADMIN_FAIL",
  UM_CREATE_CUSTOMER_ADMIN_SUCCESS: "UM_CREATE_CUSTOMER_ADMIN_SUCCESS",
  UM_EDIT_SUPPORT_ADMIN: "UM_EDIT_SUPPORT_ADMIN",
  UM_EDIT_SUPPORT_ADMIN_RESPONSE: "UM_EDIT_SUPPORT_ADMIN_RESPONSE",
  UM_GET_ADMIN_DETAILS: "UM_GET_ADMIN_DETAILS",
  UNSELECT_CUSTOMER_ADMIN_ROW: "UNSELECT_CUSTOMER_ADMIN_ROW",
};

export default CustomerAdminActions;
