import Badge from './Badge';
import BadgeList from './BadgeList';

export type { BadgeProps } from './Badge';

export type { BadgeListProps } from './BadgeList';

Badge.List = BadgeList;

export default Badge;
