/**
 * @description Action constants to be used across the project in admin-ui
 *
 * format: {feature}_{action}
 */
const TenantActions = {
  // action to get tenant list in all tenant page
  AM_TENANT_LIST_LOADER_END: "AM_TENANT_LIST_LOADER_END", // loader for tenant list
  AM_TENANT_LIST_LOADER_START: "AM_TENANT_LIST_LOADER_START", // loader for tenant list
  CLEAR_CONTACT_CONFLICT: "CLEAR_CONTACT_CONFLICT",
  CLEAR_POST_TENANT_RESPONSE: "CLEAR_POST_TENANT_RESPONSE",
  CLOSE_EDIT_TENANT: "CLOSE_EDIT_TENANT",
  CLOSE_TENANT_DETAILS: "CLOSE_TENANT_DETAILS",
  CREATE_TENANT: "CREATE_TENANT",
  DELETE_TENANT: "DELETE_TENANT",
  EDIT_TENANT_CLEAR_ERROR: "EDIT_TENANT_CLEAR_ERROR",
  EDIT_TENANT_DETAILS: "EDIT_TENANT_DETAILS",
  EDIT_TENANT_DETAILS_FAIL: "EDIT_TENANT_DETAILS_FAIL",
  EDIT_TENANT_DETAILS_SUCCESS: "EDIT_TENANT_DETAILS_SUCCESS",
  GET_CHECKED_TENANT_DETAILS: "GET_CHECKED_TENANT_DETAILS",
  GET_TENANT_LICENSE: "GET_TENANT_LICENSE",
  GET_COUNTRIES_LIST: "GET_COUNTRIES_LIST",
  GET_CURRENCY_LIST: "GET_CURRENCY_LIST",
  GET_LANGUAGE_LIST: "GET_LANGUAGE_LIST",
  GET_MARKET_ORGANIZATION: "GET_MARKET_ORGANIZATION",
  GET_STATES_LIST: "GET_STATES_LIST",
  GET_TENANTS: "GET_TENANTS",
  GET_TENANT_DETAILS: "GET_TENANT_DETAILS",
  GET_TENANT_LIST: "GET_TENANT_LIST",
  GET_TENANT_TYPE: "GET_TENANT_TYPE",
  GET_USER_ROLES: "GET_USER_ROLES",
  OM_GET_CCMS_INFO: "OM_GET_CCMS_INFO",
  OM_GET_CCMS_INFO_SUCCESS: "OM_GET_CCMS_INFO_SUCCESS",
  OM_TENANT_MODULE_UPDATE: "OM_TENANT_MODULE_UPDATE",
  POST_TENANT_DETAILS: "POST_TENANT_DETAILS",
  POST_TENANT_FAIL: "POST_TENANT_FAIL",
  POST_TENANT_RESPONSE: "POST_TENANT_RESPONSE",
  PUT_COUNTRIES_LIST: "PUT_COUNTRIES_LIST",
  PUT_CURRENCY_LIST: "PUT_CURRENCY_LIST",
  PUT_LANGUAGE_LIST: "PUT_LANGUAGE_LIST",
  PUT_MARKET_ORGANIZATION: "PUT_MARKET_ORGANIZATION",
  PUT_STATES_LIST: "PUT_STATES_LIST",
  PUT_TENANTS: "PUT_TENANTS",
  PUT_TENANT_LICENSE: "PUT_TENANT_LICENSE",
  PUT_TENANT_TYPE: "PUT_TENANT_TYPE",
  PUT_USER_ROLES: "PUT_USER_ROLES",
  SAP_LINK: "SAP_LINK",
  SAP_RESYNC: "SAP_RESYNC",
  SET_CHECKED_TENANT_DETAILS: "SET_CHECKED_TENANT_DETAILS",
  SET_TENANT_DETAILS: "SET_TENANT_DETAILS",
  SET_TENANT_SEARCH_QUERY: "SET_TENANT_SEARCH_QUERY",
  SET_TENANT_SORT_QUERY: "SET_TENANT_SORT_QUERY",
  TENANT_ACTIVATION_STATUS: "ACTIVATION_STATUS",
  TENANT_DESELECT_ROW: "TENANT_DESELECT_ROW",
  TENANT_SELECT_GRID_CHECKBOX: "  TENANT_SELECT_GRID_CHECKBOX",
  TENANT_SELECT_ROW: "TENANT_SELECT_ROW",
  TENANT_SET_SELECTED_ROW: "TENANT_SET_SELECTED_ROW",
  TENANT_UNSELECT_ALL_ROWS: "TENANT_UNSELECT_ALL_ROWS",
  TENANT_UNSELECT_GRID_CHECKBOX: "  TENANT_UNSELECT_GRID_CHECKBOX",
  UM_GET_CONTACT_CONFLICT: "UM_GET_CONTACT_CONFLICT",
  UM_GET_CONTACT_CONFLICT_SUCCESS: "UM_GET_CONTACT_CONFLICT_SUCCESS",
  UM_GET_JOB_FUNCTIONS: "UM_GET_JOB_FUNCTIONS",
  UM_GET_JOB_FUNCTIONS_SUCCESS: "UM_GET_JOB_FUNCTIONS_SUCCESS",
  UPDATE_TENANT_ACTV_STATUS: "UPDATE_TENANT_ACTV_STATUS",
  UPDATE_TENANT_LIST: "UPDATE_TENANT_LIST",
  GET_DEFAULT_COUNT: "GET_DEFAULT_COUNT",
  PUT_TENANT_DEFAULT_COUNT: "PUT_TENANT_DEFAULT_COUNT",
  UNDO_DELETE_TENANT: "UNDO_DELETE_TENANT",
};

export default TenantActions;
