import { AuthorizationRequestJson, AuthorizationServiceConfigurationJson, TokenRequestJson } from "@openid/appauth";
import ApplicationConstants from "../applicationConstants";
import ServiceUrl from "../utils/enums/serviceUrlEnum";
import { getVersionedApiUrl } from "am-web-ui-shared/helpers";
declare global {
  interface Window {
    ASSET_MANAGEMENT_URLS: any;
    __LIST_PAGE_SIZE: number;
    ASSET_MANAGEMENT_ADMIN_PORTAL_AUTH_CONFIG: any;
  }
}

export const getPageSize = () => {
  return window.__LIST_PAGE_SIZE || ApplicationConstants.LIMIT.LIM100;
};

/**
 * @description Function to return API endpoint
 * @param {String} - serviceURl - take service URL
 *
 */
export const getApiUrl = (seriveUrl: ServiceUrl, windowData: any = window) => {
  return (
    windowData.ASSET_MANAGEMENT_URLS &&
    (windowData.ASSET_MANAGEMENT_URLS[ServiceUrl[seriveUrl]] || windowData.ASSET_MANAGEMENT_URLS.USER_MGMT)
  );
};

/**
 * @description - Concatinate the firstName and lastName of the user.
 *
 * @param firstName - First name of the user.
 * @param lastName - Last name of the user.
 */
export const getFullName = (firstName: string, lastName: string) => {
  return `${firstName || ""} ${lastName || ""}`;
};

export const getOrgMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.ORG_MGMT, path, params);
};

export const getUserMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.USER_MGMT, path, params);
};

export const getDocMgmtEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.DOC_MGMT, path, params);
};

export const getFeatureFlagsEndpoint = (path, params = null) => {
  return getVersionedApiUrl(ServiceUrl.FEATURE_FLAGS, path, params);
};
