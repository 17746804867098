const ApplicationConstants = {
  LENGTH: {
    LEN2: 2,
  },
  LIMIT: {
    LIM100: 100,
  },
  TIMEOUT: {
    TOUT100: 100,
    TOUT200: 200,
  },
  S4_CUSTOMERS_MARKET_ORG_LIST: [
    "0500",
    "0300",
    "2300",
    "2800",
    "3700",
    "3800",
    "2500",
    "4200",
    "2400",
    "4100",
    "2900",
    "4000",
    "2700",
    "3300",
    "4400",
    "4600",
    "2600",
    "0700",
    "7000",
    "0900",
    "0400",
    "3200",
    "0001",
    "2100",
    "5000",
    "0800",
    "3100",
    "7500",
    "8450",
    "9800",
    "8400",
    "8200",
    "9250",
    "9200",
    "9050",
    "9150",
    "6800",
    "8700",
    "6000",
    "8750",
    "8300",
    "8650",
    "3000",
    "8100",
    "2000",
  ],
};

export default ApplicationConstants;
