/**
 * @description This file shows tabs that we display
 * in the header. And based on the tab selection we
 * load the particular module.
 */
import { Tab } from "am-web-ui-shared/elements";
import { HelperLodash } from "am-web-ui-shared/helpers";
import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { UserAccessEnum } from "../utils/enums/tenantEnum";

export const TabWrapper = styled.div`
  background-color: #ffffff;
`;

const tabTypes = {
  menuItem: "menuItem",
  menuList: "menuList",
  subMenuItem: "subMenuItem",
};
export interface INavBarProps extends WithTranslation {
  history: any;
  translate: any;
  location: any;
  currentUserRole: { code: string };
}
export interface INavBarState {
  selectedIndex: number;
}
export const tabLinks = [
  { labelKey: "common:TAB_CUSTOMERS", uri: "customers" },
  { labelKey: "common:TAB_USERS", uri: "users" },
];
export class NavBar extends React.Component<INavBarProps, INavBarState> {
  constructor(props) {
    super(props);
    // Remove "/" from path and get the tab index.
    let path = this.props.location.pathname;
    path = path.substring(1, path.length);
    let index = tabLinks.findIndex((link) => link.uri === path);
    index = index > -1 ? index : 0;
    this.state = {
      selectedIndex: index,
    };
  }

  /**
   * @description Function for selection of particular tab
   * @memberof NavBar
   * @param {String} - get the index number of the tab
   */

  handleTabSelection(index) {
    this.props.history.push(HelperLodash.get(tabLinks, [index, "uri"]));
    this.setState({ selectedIndex: index });
  }

  checkAccess = (tabMenuItem) => {
    if (tabMenuItem.uri === "users") {
      return this.props.currentUserRole?.code === UserAccessEnum.ADMIN_PORTAL;
    }
    return true;
  };

  render() {
    const { t } = this.props;
    return (
      <TabWrapper>
        <Tab
          selected={this.state.selectedIndex}
          showContent={false}
          onSelect={(nextIndex) => this.handleTabSelection(nextIndex)}
        >
          {tabLinks.map(
            (tabMenuItem, key) =>
              this.checkAccess(tabMenuItem) && (
                <Tab.TabItem
                  htmlId={tabMenuItem.uri}
                  key={key}
                  label={t(tabMenuItem.labelKey)}
                  index={key}
                  type={tabTypes.menuItem}
                />
              ),
          )}
        </Tab>
      </TabWrapper>
    );
  }
}

/**
 * @description - This is used to wrap the NavBar component with withTranslation HOC.
 */
const translatedNavBar: any = withTranslation()(NavBar);

export default withRouter(translatedNavBar) as any;
