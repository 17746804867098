// @ts-ignore
import { API_DELAY_TIME } from "ADMIN_TARGET_BUILD/config";
// @ts-ignore
import { CustomerAdminActions, TenantActions } from "custom-actions-admin";
import { put, takeLatest, delay } from "redux-saga/effects";
import CustomerAdminAPI, { getAdminDetails, getCustomerAdmins } from "../../api/customerAdminApi";
import { call } from "../common/commonSaga";

// saga for creating a admin customer
export function* createCustomerAdminSaga() {
  yield takeLatest(CustomerAdminActions.UM_CREATE_CUSTOMER_ADMIN, createCustomerAdmin);
}

// method for handling api calls for admin user creation
function* createCustomerAdmin(action) {
  let response = yield call(() => {
    return CustomerAdminAPI.createCustomerAdmin(action.customerAdminData, action.tenantCategory);
  });

  if (response && response.error) {
    // if part is executed when we have buisness error like email id is not in IDM
    // else part handles case of errors like internal server errors
    response = response.error;
    yield put({ type: CustomerAdminActions.UM_CREATE_CUSTOMER_ADMIN_FAIL, payload: response });
  } else {
    yield put({ type: CustomerAdminActions.UM_CREATE_CUSTOMER_ADMIN_SUCCESS, payload: response });
    yield delay(API_DELAY_TIME);
    yield put({
      type: CustomerAdminActions.GET_CUSTOMER_ADMINS_LIST,
      tenantId: action.customerAdminData.tenantId,
      tenantCategory: action.tenantCategory,
    });
    yield put({ type: TenantActions.GET_TENANT_DETAILS, tenantId: action.customerAdminData.tenantId });
  }
}

/* saga for editing a support admin customer */

export function* editSupportAdminSaga() {
  yield takeLatest(CustomerAdminActions.UM_EDIT_SUPPORT_ADMIN, editSupportAdmin);
}

// method for handling api calls for admin user creation
export function* editSupportAdmin(action) {
  let response = yield call(() => {
    return CustomerAdminAPI.editSupportAdmin(action.adminDetails, action.adminId);
  });

  if (response && response.error) {
    response = response.error;
  }

  yield put({ type: CustomerAdminActions.UM_EDIT_SUPPORT_ADMIN_RESPONSE, payload: response });
  yield delay(API_DELAY_TIME);
  yield put({
    type: CustomerAdminActions.GET_CUSTOMER_ADMINS_LIST,
    tenantId: action.adminDetails.tenantId,
    tenantCategory: action.tenantCategory,
  });
  yield put({ type: TenantActions.GET_TENANT_DETAILS, tenantId: action.adminDetails.tenantId });
}

// saga to get Customer Admin List
export function* customerAdminsListSaga() {
  yield takeLatest(CustomerAdminActions.GET_CUSTOMER_ADMINS_LIST, fetchCustomerAdminsList);
}

// method for handling api calls to get Customer Admin List
export function* fetchCustomerAdminsList(action) {
  let response = yield call(() => {
    return getCustomerAdmins(action.tenantId, action.tenantCategory);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: CustomerAdminActions.CUSTOMER_ADMINS_RESPONSE, payload: response });
}

// saga to get Customer Admin List
export function* adminDetailsSaga() {
  yield takeLatest(CustomerAdminActions.UM_GET_ADMIN_DETAILS, fetchAdminDetails);
}

// method for handling api calls to get Customer Admin List
export function* fetchAdminDetails(action) {
  let response = yield call(() => {
    return getAdminDetails(action.tenantId, action.emailId);
  });
  if (response.error) {
    response = [];
  }
  yield put({ type: CustomerAdminActions.UM_ADMIN_DETAILS_RESPONSE, payload: response });
}
