import {
  AuthorizationListener,
  AuthorizationNotifier,
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
  StringMap,
  TokenRequest,
} from "@openid/appauth";
import NoHashQueryStringUtils from "./noHashQueryStringUtils";
import { getCodeChallenge } from "./authCryptoHelpers";
import HiltiTokenRequestHandler from "./hiltiTokenRequestHandler";
import { setCustomerToken } from "./hiltiTokenStore";
import { v4 as uuidv4 } from "uuid";
import { FeatureFlagValue, TenantCategory } from "../../utils/enums/tenantEnum";
//@ts-ignore
import SingletonFeatureManager from "ADMIN_TARGET_BUILD/featureFlagManager/featureFlagManager";

const { ASSET_MANAGEMENT_ADMIN_PORTAL_AUTH_CONFIG: authConfig = {} } = window;

const login = async (originCustomerId, country) => {
  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );
  authConfig.state = uuidv4();
  const authServiceConfig = new AuthorizationServiceConfiguration(authConfig);
  const authRequest = new AuthorizationRequest(authConfig);
  authRequest.extras = {};
  authRequest.extras.code_challenge = await getCodeChallenge();
  authRequest.extras.origin_customer_id = originCustomerId;
  authRequest.extras.country = country;

  authorizationHandler.performAuthorizationRequest(authServiceConfig, authRequest);
};

const completeAuthorisationCallback = (setAuthError: (param: string | null) => void, code: string | null) => {
  const authServiceConfig = new AuthorizationServiceConfiguration(authConfig);
  const authorizationListener: AuthorizationListener = (request, response, error) => {
    if (response) {
      const tokenRequestExtras: StringMap = {};
      tokenRequestExtras.state = authConfig.state;
      if (request && request.internal) {
        tokenRequestExtras.code_verifier = request.internal.code_verifier;
      }

      const tokenRequest = new TokenRequest(authConfig);
      if (code) {
        tokenRequest.code = code;
      }
      tokenRequest.extras = tokenRequestExtras;
      const tokenHandler = new HiltiTokenRequestHandler(new FetchRequestor());
      tokenHandler
        .performHiltiTokenRequest(authServiceConfig, tokenRequest)
        .then((oResponse) => {
          setCustomerToken(oResponse);
          window.close();
        })
        .catch((oError) => {
          setAuthError(oError);
        });
    } else if (error) {
      setAuthError(error.error);
    }
  };

  const notifier = new AuthorizationNotifier();
  const authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );
  authorizationHandler.setAuthorizationNotifier(notifier);
  notifier.setAuthorizationListener(authorizationListener);
  authorizationHandler.completeAuthorizationRequestIfPossible();
};

const shouldUseCustomerToken = (method, url, data) => {
  const customeTSLoginEnabled = SingletonFeatureManager.isFeatureEnabled(FeatureFlagValue.CUSTOMER_TS_LOGIN);
  if (!customeTSLoginEnabled) {
    return false;
  }

  const endpoints = [
    { method: "GET", pattern: /\/v1\/customer\/\d+/ },
    { method: "PATCH", pattern: /\/v1\/admin\/tenants\/\d+\/employees\/\d+\/promote\/?$/ },
    { method: "POST", pattern: /\/v1\/tenants\/\d+\/ccms-contacts\/find-contacts\/?$/ },
  ];

  const matched = endpoints.some((endpoint) => endpoint.method === method && endpoint.pattern.test(url));
  if (matched) {
    return true;
  }

  const createTenantEndpoint = { method: "POST", pattern: /\/v1\/tenants\/?$/ };
  const tenantEndpointMatched = createTenantEndpoint.method === method && createTenantEndpoint.pattern.test(url);
  if (tenantEndpointMatched && data?.tenantCategory === TenantCategory.STANDARD) {
    return true;
  }

  const createUsersAPI = { method: "POST", pattern: /\/v1\/tenants\/\d+\/worker-users\/?$/ };
  if (
    method === createUsersAPI.method &&
    createUsersAPI.pattern.test(url) &&
    data?.userRole === "ADMIN" &&
    data?.isExtendAccess === true
  ) {
    return true;
  }

  return false;
};
export { login, completeAuthorisationCallback, shouldUseCustomerToken };
