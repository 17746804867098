/**
 * @description Utility file to filter or update the grid list. Iterators on the grid list.
 */
const MAX_SLECTION_COUNT = 500;

export enum checkedListInfoEnum {
  excludedIds = "excludedIds",
  primaryRowIds = "primaryRowIds",
  checkedRows = "checkedRows",
  hierarchyRowsInfo = "hierarchyRowsInfo",
}

export enum ListMetaEnum {
  IsChecked = "isChecked",
  IsRowCheckedOnCollapsedMode = "isRowCheckedOnCollapsedMode",
  IsExpanded = "isExpanded",
}

interface IHierarchyRow {
  id: number;
  [ListMetaEnum.IsRowCheckedOnCollapsedMode]: boolean;
  excludedIds: number[];
  primaryRowIds: number[];
}

export interface ICheckedListInfo {
  count: number;
  excludedIds: any[];
  hierarchyRowsInfo?: IHierarchyRow[];
  primaryRowIds?: number[];
  checkedRows: any[];
}

export const clearAllCheckedRows = (list) => {
  (list || []).forEach((row) => {
    if (isChildDataExists(row)) {
      clearAllCheckedRows(row.childData.response);
    }
    row.metaData = {
      ...row.metaData,
      [ListMetaEnum.IsRowCheckedOnCollapsedMode]: false,
      isChecked: false,
    };
  });
  return list;
};

/**
 * @description Function returns the list with isSelected property true
 * @param {Array} - list - array of row objects
 */
export const getSelectedList = (list: any[] = []) => {
  return list && list.filter((row) => row.isSelected);
};

export const getSelectedRowDetail = (list: any[] = []) => {
  const selectedRow = getSelectedList(list);

  return selectedRow ? selectedRow[0] : null;
};

/**
 * @description Function toggles the row selection
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const toggleListSelection = (list, rowId: number) => {
  (list || []).forEach((row) => {
    if (row.model && row.model.id === rowId) {
      row.isSelected = !row.isSelected;
    }
  });
  return list;
};

/**
 * @description Function toggles the row selection
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const setRowSelection = (list: any = [], rowId = -1, isSelected = false, key: string) => {
  const row = list.filter((item) => item && item[key] === rowId);
  if (row.length) {
    row[0].isSelected = isSelected;
  }
  return list;
};

export const getFirstRowFromList = (list) => {
  return list.length && list[0];
};

export const getIdsFromList = (list, key) => list.map((item) => item[key]);

/**
 * @description Function toggles the row selection
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const clearAllSelection = (list: any = []) => {
  list.forEach((row) => {
    row.isSelected = false;
  });
  return list;
};

/**
 * @description Function toggles the row selection
 * @param {Array} - list - array of row objects
 * @param {number} - rowId for toggle selection
 */
export const selectAllRows = (list: any = []) => {
  list.forEach((row) => {
    row.isSelected = true;
  });
  return list;
};

/**
 * @description Function returns the updated list with isSelected Property added and remove response from the manufacturer
 * @param {Array} - list - array of row objects
 */
export const appendSelectedPropertyToList = (list = []) => {
  return (list || []).map((element) => {
    element.isSelected = false;
    return element;
  });
};

/**
 * @description Function returns the updated list with isSelected Property added and remove response from the manufacturer
 * @param {selectedList} - list - array of row objects
 * @param {key} - key to retrieve from the object in the list
 */
export const getKeysFromList = (selectedList, key) => {
  return selectedList.map((list) => {
    return list[key];
  });
};

/**
 * @description Function returns the updated list with isSelected Property added and remove response from the manufacturer
 * @param {Array} - list - array of row objects
 * @param {property} - key to retrieve from the object in the list
 */
export const getFlatMap = (list, property) => {
  return list.flatMap((item) => item[property]);
};

/**
 * @description Function returns the updated sort query
 * @param {property} - state of the sort query
 * @param {Array} - sortList - array of sort list
 */
export const applySort = (state, sortList) => {
  const isFoundRecord = sortList.SORT_LIST.find((item) => item.name === state.sortQuery.sortField);
  return !isFoundRecord
    ? state.previousSortQuery || {
        sortField: sortList.DEFAULT_SORT.name,
        sortType: sortList.DEFAULT_SORT.sortType,
      }
    : state.sortQuery;
};

/**
 * @description Function returns the only if one row is selected
 * @param {Array} - list - array of row objects
 */
export const getSelectedRowsInfo = (list: any[] = []) => {
  const selectedRows = getSelectedList(list);
  return {
    isOneRowSelected: selectedRows.length === 1,
    selectedRows,
  };
};

export const getSelectedRowsIdsFromList = (list: any[] = []) => {
  const selectedRows = getSelectedList(list);
  return selectedRows.map((item) => item.id);
};

export const setCheckboxSelection = (list: any = [], rowId = -1, isChecked = false, key: string) => {
  let count = 0;
  let row;
  list.forEach((item) => {
    if (item.isChecked) {
      count = count + 1;
    }
    if (item[key] === rowId) {
      row = item;
    }
  });

  if (row) {
    isChecked = count < MAX_SLECTION_COUNT && isChecked;
    row.metaData = { ...row.metaData, isChecked };
  }
  return list;
};

export const getCheckedRowsInfo = (list: any[] = [], metaKeys?, totalRecords?) => {
  const initialCheckedListInfo: ICheckedListInfo = {
    checkedRows: [],
    count: 0,
    excludedIds: [],
    hierarchyRowsInfo: [],
    primaryRowIds: [],
  };
  const checkedListInfo = getCheckedList(list, initialCheckedListInfo, metaKeys);
  const checkedRows = checkedListInfo.checkedRows;

  return {
    checkedListInfo,
    checkedRows,
    checkedRowsExcludingChildren: getCheckedRowsExcludingChildren(list),
    checkedRowsIds: checkedRows.map((item) => item.tenantId || item.assetId),
    checkedRowscount: checkedListInfo.count,
    firstCheckedRow: checkedRows.length > 0 ? checkedRows[0] : null,
    isAnyRowChecked: checkedListInfo.count > 0,
    isMultipleRowChecked: checkedListInfo.count > 1,
    isOneRowChecked: checkedListInfo.count === 1,
    totalCheckedCount: getSelectAllCount(totalRecords, checkedListInfo),
  };
};

export const getCheckedList = (list, prevSelectedListObj, metaKeys?) => {
  let checkedListInfo: ICheckedListInfo = {
    checkedRows: [...prevSelectedListObj.checkedRows],
    count: prevSelectedListObj.count,
    excludedIds: [...prevSelectedListObj.excludedIds],
    hierarchyRowsInfo: [...prevSelectedListObj.hierarchyRowsInfo],
    primaryRowIds: [...prevSelectedListObj.primaryRowIds],
  };
  (list || []).forEach((row) => {
    if ((row.metaData && row.metaData.isChecked) || isChildDataExists(row)) {
      if (isHierarchialRow(row)) {
        // row may not be checked but expanded then update its child info.
        checkedListInfo = checkedListForHierarchyRow(row, checkedListInfo, metaKeys);
      } else {
        checkedListInfo = updateForNonHierachialRowsInfo(checkedListInfo, row, metaKeys);
      }
    } else {
      updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.excludedIds);
    }
  });
  return checkedListInfo;
};

export const getCheckedRowsExcludingChildren = (list: any[] = [], prevSelectedRows: any[] = []) => {
  let selectedRows = prevSelectedRows.concat((list || []).filter((row) => row.metaData && row.metaData.isChecked));
  const rowsWithChildData = (list || []).filter((row) => isChildDataExists(row));
  for (const item of rowsWithChildData) {
    if (!item.metaData.isChecked) {
      selectedRows = getCheckedRowsExcludingChildren(item.childData.response, selectedRows);
    }
  }
  return selectedRows;
};

export const getSelectAllCount = (totalCount = 0, checkedListInfo) => {
  if (checkedListInfo && checkedListInfo.excludedIds) {
    totalCount = totalCount - checkedListInfo.excludedIds.length;
  }
  return totalCount;
};

const checkedListForHierarchyRow = (row, checkedListInfo, metaKeys?) => {
  if (!isChildDataExists(row)) {
    // row selected and never expanded
    checkedListInfo = handleHierarchyRowWithoutChildData(checkedListInfo, row, metaKeys);
  } else {
    // row may or may not selected but has childData. iterate each child and get excluded ids
    checkedListInfo = handleHierarchyRowWithChildData(checkedListInfo, row, metaKeys);
  }
  return checkedListInfo;
};

const isChildDataExists = (row) => {
  return row.childData && row.childData.response;
};

export const isHierarchialRow = (row) => {
  return row.childCount && row.metaData && !row.metaData.skipHierarchy;
};

const updateForNonHierachialRowsInfo = (checkedListInfo, row, metaKeys) => {
  checkedListInfo.checkedRows.push(row);
  updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.primaryRowIds);
  checkedListInfo.count += 1;
  return checkedListInfo;
};

const updateIdsInthePath = (checkedListInfo, row, metaKeys, propertyName) => {
  const idKey = getIdKey(metaKeys);
  const hierarchialRows = checkedListInfo.hierarchyRowsInfo;
  const hierarchialRow = hierarchialRows.find((hRow) => hRow.id.toString() === getParentId(row));
  if (hierarchialRow) {
    // If a row is part of some hierarchial row then its information should be updated in
    // its parent object rather than at root level.
    hierarchialRow[propertyName].push(row[idKey]);
  } else {
    /*
          case: When hierarchial row is expanded and then checked
          and it is at root level.
        */
    checkedListInfo[propertyName].push(row[getIdKey(metaKeys)]);
  }
};

const handleHierarchyRowWithoutChildData = (checkedListInfo, row, metaKeys?) => {
  checkedListInfo.hierarchyRowsInfo.push({
    excludedIds: [],
    hasChildData: false,
    id: row[getIdKey(metaKeys)],
    primaryRowIds: [],
    [ListMetaEnum.IsRowCheckedOnCollapsedMode]: true,
  });
  checkedListInfo.checkedRows.push(row);
  checkedListInfo.count += row.childCount + 1;
  updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.primaryRowIds);
  return checkedListInfo;
};

const handleHierarchyRowWithChildData = (checkedListInfo, row, metaKeys?) => {
  const idKey = getIdKey(metaKeys);
  const isRowCheckedOnCollapseMode = Boolean(row.metaData[ListMetaEnum.IsRowCheckedOnCollapsedMode]);
  // row may or may not be  selected and has child data. Iterate each child and append to selected list.
  updateCheckedListInfo(checkedListInfo, checkedListInfoEnum.hierarchyRowsInfo, {
    excludedIds: [],
    hasChildData: true,
    id: row[idKey],
    primaryRowIds: [],
    [ListMetaEnum.IsRowCheckedOnCollapsedMode]: isRowCheckedOnCollapseMode,
  });
  updateCheckedListInfo(checkedListInfo, checkedListInfoEnum.checkedRows, row);
  if (isRowCheckedOnCollapseMode) {
    checkedListInfo.count += row.childCount - getExclusionCount(row.childData.response);
  }
  if (row.metaData && row.metaData.isChecked) {
    checkedListInfo.count += 1;
    updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.primaryRowIds);
  } else {
    updateIdsInthePath(checkedListInfo, row, metaKeys, checkedListInfoEnum.excludedIds);
  }
  checkedListInfo = getCheckedList(row.childData.response, checkedListInfo, metaKeys);
  return checkedListInfo;
};

const getIdKey = (meta) => {
  return meta && meta.id ? meta.id : "assetId";
};

export const updateCheckedListInfo = (checkedListInfo, propertyName, PropertyValue) => {
  if (Array.isArray(checkedListInfo[propertyName])) {
    checkedListInfo[propertyName].push(PropertyValue);
  }
};

export const getExclusionCount = (list) => {
  let count = 0;
  list.forEach((item) => {
    if (item.metaData && !item.metaData.checked) {
      count += item.childCount + 1;
    }
  });
  return count;
};

export const getParentId = (item) => {
  if (!item.path) {
    return null;
  }
  const pathArr = item.path.split(".");
  return pathArr[pathArr.length - 1];
};
