import React from "react";

// @ts-ignore
import LogoExternal from "ADMIN_TARGET_BUILD/components/header/logo";

class Logo extends React.PureComponent {
  render() {
    return <LogoExternal />;
  }
}

export default Logo;
