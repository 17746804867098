// @ts-ignore
import { CustomerAdminActions, TenantActions } from "../../actionConstants";
import { createReducer } from "../../utils/commonUtils";
import { clearAllSelection, setRowSelection } from "../../utils/listUtils";

const initialState = {
  customerAdmins: null,
  isNewTenantAdded: false,
};

const selectRow = (state, action) => {
  const customerAdminList = state.customerAdmins;
  clearAllSelection(customerAdminList);
  return {
    ...state,
    customerAdmins: setRowSelection(customerAdminList, action.id, true, "id"),
  };
};
const unselectRow = (state, action) => {
  const customerAdminList = state.customerAdmins;
  return {
    ...state,
    customerAdmins: setRowSelection(customerAdminList, action.id, false, "id"),
  };
};

const editSupportAdminResponse = (state, action) => {
  return {
    ...state,
    supportAdminEditResponse: action.payload,
  };
};

const customersAdminReducer = createReducer(initialState, {
  [CustomerAdminActions.UM_EDIT_SUPPORT_ADMIN_RESPONSE]: editSupportAdminResponse,
  [CustomerAdminActions.UNSELECT_CUSTOMER_ADMIN_ROW]: unselectRow,
  [CustomerAdminActions.SELECT_CUSTOMER_ADMIN_ROW]: selectRow,
});

/**
 * @description customer admin reducer constants
 */
const customerAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case CustomerAdminActions.CUSTOMER_ADMINS_RESPONSE:
      return {
        ...state,
        customerAdmins: clearAllSelection(action.payload),
      };
    case CustomerAdminActions.UM_CREATE_CUSTOMER_ADMIN_SUCCESS:
      return {
        ...state,
        createCustomerAdminError: null,
        createCustomerAdminResponse: action.payload,
        isNewTenantAdded: true,
      };
    case CustomerAdminActions.UM_CREATE_CUSTOMER_ADMIN_FAIL:
      return {
        ...state,
        createCustomerAdminError: action.payload,
        createCustomerAdminResponse: null,
      };
    case CustomerAdminActions.UM_CLEAR_CREATE_CUSTOMER_ADMIN_RESPONSE:
      return {
        ...state,
        createCustomerAdminError: null,
        createCustomerAdminResponse: null,
      };
    case TenantActions.UM_GET_CONTACT_CONFLICT_SUCCESS:
      return {
        ...state,
        contactConflictResponse: action.payload,
      };
    case TenantActions.CLEAR_CONTACT_CONFLICT:
      return {
        ...state,
        contactConflictResponse: null,
      };
    case CustomerAdminActions.CLEAR_CUSTOMER_ADMINS_LIST:
      return {
        ...state,
        customerAdmins: null,
        isNewTenantAdded: false,
      };
    case CustomerAdminActions.UM_ADMIN_DETAILS_RESPONSE:
      return {
        ...state,
        adminDetails: action.payload,
      };
    default:
      return customersAdminReducer(state, action);
  }
};

export default customerAdminReducer;
