/**
 * @description This file is for api hitting using
 * axios for the logout web UI user.
 */
import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
/**
 * @description Function to logout webUI user
 * @memberof logoutModule
 */

export const logoutAdminUI = async (): Promise<any | IErrorResponse> => {
  return Gateway.post(`/auth/admin/logout`, null, null, null) as unknown as any;
};
