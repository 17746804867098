export const appConstants = {
  ADMIN_LIST_HEIGHT: 271,
  ADMIN_TOKEN: "adminToken",
  DAYS_ADDED: 30,
  DEFAULT_START_PAGE: 0,
  DEFAULT_THRESHOLD: 100,
  LOGGED_IN_MARKET_ORG: "loginMarketOrg",
  MARKETING_WEBSITE_URL: "http://www.hilti.com",
  MIN_DAYS: 120,
  MODAL_FOOTER_HEIGHT: 64,
  REDIRECT_TO_LOGIN: "login",
  SUPPORT_ADMIN_EXPIRY_DAYS: 150,
};
export const languages = [
  { name: "English", code: "en-US" },
  { name: "German", code: "de-DE" },
];
export const API_DELAY_TIME = 1000;
export const NO_OF_SECONDS_IN_A_MINUTE = 60;
export const API_TIMEOUT = NO_OF_SECONDS_IN_A_MINUTE * API_DELAY_TIME;
export const API_DATE_FORMAT = "YYYY-MM-DD";
export enum MultiSelectValidation {
  MULTISELECTCOUNT = 500,
}
export const FileTypeList = {
  TYPE: {
    XLS: "xls",
    XLSX: "xlsx",
    XLSX_TYPE: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    XLS_TYPE: "application/vnd.ms-excel",
  },
};

export const SortList = {
  TENANTS: {
    DEFAULT_SORT: { name: "customerName", label: "Name", sortField: "customerName", sortType: "+" },
    SORT_LIST: [
      { name: "licenseType", label: "LicenseType", sortField: "licenseType" },
      { name: "licenseBundle", label: "LicenseBundle", sortField: "licenseBundle" },
      { name: "customerName", label: "Name", sortField: "customerName" },
      { name: "domain", label: "Domain", sortField: "domain" },
      { name: "language", label: "Language", sortField: "language" },
      { name: "tenantStatus", label: "Status", sortField: "tenantStatus" },
      { name: "createdAt", label: "DateCreated", sortField: "createdAt" },
      { name: "activeModules", label: "NumberActiveModules", sortField: "activeModules" },
      { name: "license", label: "License", sortField: "license" },
    ],
  },
};
export const POLLING_DELAY_TIME = 2000;
export enum ExportStatus {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  DOWNLOADED = "DOWNLOADED",
  FAILED = "FAILED",
}
