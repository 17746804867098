enum ServiceUrl {
  USER_MGMT = "USER_MGMT",
  ORG_MGMT = "ORG_MGMT",
  ASSET_MGMT = "ASSET_MGMT",
  ORCHESTRATION = "ORCHESTRATION",
  HILTI_INTEGRATION = "HILTI_INTEGRATION",
  DOC_MGMT = "DOC_MGMT",
  FEATURE_FLAGS = "FEATURE_FLAGS",
}

export default ServiceUrl;
