const AdminUsersActionConstants = {
  ADD_ADMIN_USER: "ADD_ADMIN_USER",
  ADD_ADMIN_USER_SUCCESS: "ADD_ADMIN_USER_SUCCESS",
  FETCH_ADMIN_USERS: "FETCH_ADMIN_USERS",
  FETCH_ADMIN_USERS_SUCCESS: "FETCH_ADMIN_USERS_SUCCESS",
  ADMIN_USERS_LOADER_START: "ADMIN_USERS_LOADER_START",
  ADMIN_USERS_LOADER_END: "ADMIN_USERS_LOADER_END",
  DELETE_ADMIN_USER: "DELETE_ADMIN_USER",
  SELECT_ADMIN_USER: "SELECT_ADMIN_USER",
  DESELECT_ADMIN_USER: "DESELECT_ADMIN_USER",
};

export default AdminUsersActionConstants;
