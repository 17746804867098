import { call as sagaCall, put } from "redux-saga/effects";
import ErrorCodes from "../../../common/utils/enums/errorCodes";
import ToastrActions from "../../actionConstants/toastrConstant";

/**
 * @description Saga created to show toast message for System Errors.
 * @prop {any} func - Function to invoke api functions.
 * @prop {any} args - Function arguments corrsponding to api function.
 */
export function* call(func, args?) {
  const response = yield sagaCall(func, args);
  return yield handleSagaCallResponse(response);
}

/**
 * method to handle response from saga call
 *
 * @param response response from saga call
 */
export function* handleSagaCallResponse(response) {
  if (!response || (response && !response.error) || (response.error && response.error.isCancel)) {
    return response;
  }
  const error = response.error.response;
  const systemErrorCodes = [
    ErrorCodes.E403,
    ErrorCodes.E405,
    ErrorCodes.E500,
    ErrorCodes.E502,
    ErrorCodes.E503,
    ErrorCodes.E504,
  ];
  let systemErrorMessage;
  if (!error) {
    systemErrorMessage = `errorCodes:networkError`;
  } else if (systemErrorCodes.includes(error.status)) {
    systemErrorMessage = `errorCodes:${error.status}`;
  }
  if (systemErrorMessage) {
    yield put({
      payload: { toastrMessage: systemErrorMessage, translate: true },
      type: ToastrActions.SHOW_TOASTR,
    });
    return { ...response, isSystemError: true };
  }
  return response;
}
