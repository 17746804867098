import { takeLatest } from "redux-saga/effects";
import { logoutActions } from "../../actionConstants/logoutConstant";
import { logoutAdminUI } from "../../api/logoutApi";
import { call } from "../common/commonSaga";

export function* logoutSaga() {
  yield takeLatest(logoutActions.LOGOUT, logout);
}

function* logout() {
  const response = yield call(() => {
    return logoutAdminUI();
  });

  if (response && !response.error) {
    window.location.href = response.redirectUrl;
  }
}
