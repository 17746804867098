// @ts-ignore
import { UserProfileConstants } from "custom-actions-admin";

export const userMeReducer = (state = {}, action) => {
  if (action.type === UserProfileConstants.PUT_USER_ME_SUCCESS) {
    return {
      ...state,
      userMeData: action.payload,
    };
  } else {
    return state;
  }
};
