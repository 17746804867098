/**
 * @description This file is for api hitting using
 * axios for the logoin web UI user.
 */
import Gateway from "../../common/gateway/gateway";
import { IErrorResponse } from "../models/iApiError";

/**
 * @description Function to logout webUI user
 * @memberof loginModule
 */

export const getMarketOrganization = async (): Promise<any | IErrorResponse> => {
  return Gateway.getUnAuthorized(`/market-organizations`, null, null);
};
