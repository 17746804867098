import Gateway from "../../common/gateway/gateway";
import { getUserMgmtEndpoint } from "../utils/apiUtils";

export const addAdminUserApi = async (userData) => {
  return Gateway.post(getUserMgmtEndpoint(`/admin-portal-users/`), null, userData, null);
};

export const adminUsersApi = async (querysObj) => {
  return Gateway.get(getUserMgmtEndpoint(`/admin-portal-users`), querysObj);
};

export const deleteAdminUserApi = async (adminUserId) => {
  return Gateway.delete(getUserMgmtEndpoint(`/admin-portal-users/${adminUserId}`), null, null);
};
