// @ts-ignore
import { MasterActions } from "custom-actions-admin";

/**
 * @description admin master reducer
 */
export const masterDataReducer = (state = {}, action) => {
  switch (action.type) {
    case MasterActions.PUT_TIMEZONE_LIST:
      return {
        ...state,
        timezones: action.payload,
      };
    case MasterActions.OM_LANGUAGE_RESPONSE:
      return {
        ...state,
        languageList: action.payload,
      };
    default:
      return state;
  }
};
