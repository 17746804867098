import LicenseBundleActions from "../../actionConstants/licenseBundleActionConstant";

const licenseBundleReducer = (state = {}, action) => {
  switch (action.type) {
    case LicenseBundleActions.PUT_LICENSE_BUNDLES:
      return {
        ...state,
        licenseBundleDetails: action.payload,
      };
    default:
      return state;
  }
};

export default licenseBundleReducer;
