export const storageGetItem = (name) => {
  return localStorage.getItem(name);
};

export const storageSetItem = (name, data: string) => {
  localStorage.setItem(name, data);
};

export const storageClearItem = (name) => {
  localStorage.removeItem(name);
};

export const storageClearAll = () => {
  localStorage.clear();
};

export const localStorageGetObject = (name: string) => {
  return JSON.parse(localStorage.getItem(name)) || {};
};

export const localStorageSetObject = (name: string, data: object) => {
  localStorage.setItem(name, JSON.stringify(data));
};
