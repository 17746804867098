// @ts-ignore
import FailureIcon from "ADMIN_TARGET_BUILD/ui-assets/icons/error.svg";
// @ts-ignore
import InfoIcon from "ADMIN_TARGET_BUILD/ui-assets/icons/info.svg";
// @ts-ignore
import successIcon from "ADMIN_TARGET_BUILD/ui-assets/icons/positiveFeedbackSmall.svg";
// @ts-ignore
import { appConstants, POLLING_DELAY_TIME } from "ADMIN_TARGET_BUILD/config";
import { AsyncExportDialog, NotificationModal } from "am-web-ui-shared/components";
// @ts-ignore
import styled from "custom-styled-components";
import React from "react";
import { connect } from "react-redux";
import { closeToastr, showToastr, ToastrType } from "../actions/toastrAction";
import NavBar from "../components/navBar";
import { storageGetItem } from "../utils/storageUtils";
import Header from "./header/container/header";
import ILayoutProps from "./iLayoutProps";
import { pollingStart } from "../actions/asyncExportActions";
import { downloadDocument } from "../api/documentApi";
import { withTranslation } from "react-i18next";

/**
 * @interface IRoot for hide the layout.
 *
 * @props {boolean}: hide the layout is path is "login"
 */

const HeaderWrapper = styled.header``;
const Main = styled.main``;

/**
 * @class Layout
 * @extends {React.Component<ILayoutProps>}
 */
export class Layout extends React.Component<ILayoutProps> {
  componentDidMount() {
    const rootDocument = document.getElementById("root");
    if (rootDocument && rootDocument.addEventListener) {
      rootDocument.addEventListener("click", this.closeToastrNotification);
    }
  }

  componentWillUnmount() {
    const rootDocument = document.getElementById("root");
    if (rootDocument && rootDocument.removeEventListener) {
      rootDocument.removeEventListener("click", this.closeToastrNotification);
    }
  }

  /**
   * function used to close toastr notification
   * @function closeToastrNotification
   * @memberof Layout
   */
  closeToastrNotification = () => {
    this.props.closeToastr();
  };

  /**
   * function used to retrieve Toastr Icon based on the Toastr Type
   * @function getToastrIcon
   * @memberof Layout
   */
  getToastrIcon = () => {
    const type = this.props.toastrType;
    switch (type) {
      case ToastrType.error:
        return FailureIcon;
      case ToastrType.success:
        return successIcon;
      case ToastrType.info:
        return InfoIcon;
      default:
        return FailureIcon;
    }
  };
  render() {
    const { children, t, startPolling, showToaster, noHeader } = this.props;
    return (
      <>
        {!noHeader && (
          <HeaderWrapper>
            <Header translate={t} />
            {storageGetItem(appConstants.ADMIN_TOKEN) && <NavBar currentUserRole={this.props.currentUserRole} />}
          </HeaderWrapper>
        )}

        <Main className="main">{children}</Main>
        {this.props.showNotification && (
          <NotificationModal
            notificationIcon={this.getToastrIcon()}
            message={this.props.translate ? t(this.props.notificationMessage) : this.props.notificationMessage}
            handleClose={this.props.closeToastr}
          />
        )}
        <AsyncExportDialog
          delayTime={POLLING_DELAY_TIME}
          startPolling={(moduleName) => startPolling(moduleName)}
          t={t}
          downloadDocument={downloadDocument}
          showToaster={showToaster}
        />
      </>
    );
  }
}

const getNotificationMessage = (state) => state && state.toastr && state.toastr.toastrMessage;
const getShowNotification = (state) => state && state.toastr && state.toastr.showNotification;
const getToastrType = (state) => state && state.toastr && state.toastr.toastrType;
const translation = (state) => state && state.toastr && state.toastr.translate;

const mapStateToProps = (state) => ({
  notificationMessage: getNotificationMessage(state),
  showNotification: getShowNotification(state),
  toastrType: getToastrType(state),
  translate: translation(state),
  currentUserRole: state.userMe?.userMeData?.userRole,
});

const mapDispatchToProps = (dispatch) => ({
  closeToastr: () => dispatch(closeToastr()),
  startPolling: (moduleName: string) => dispatch(pollingStart(moduleName)),
  showToaster: (type: ToastrType, message: string) => dispatch(showToastr(type, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Layout));
