// @ts-ignore
import { appConstants } from "ADMIN_TARGET_BUILD/config";
import { Drilldown } from "am-web-ui-shared/elements";
import { IconName } from "am-web-ui-shared/components";
// @ts-ignore
import theme from "ADMIN_TARGET_BUILD/theme";
// @ts-ignore
import styled from "custom-styled-components-admin";
import React from "react";
import { withTranslation } from "react-i18next";
import { storageGetItem } from "../../../utils/storageUtils";
import ILogoutProps from "../iLogoutProps";
import Logo from "../logo";
import UserProfile from "../userProfile/userProfileComp";

const HeaderDrillDown = styled(Drilldown)`
  background-color: ${(props) => props.theme.colors.base.white};
  font-family: ${(props) => props.theme.fontBold.fontFamily};
  font-weight: ${(props) => props.theme.fontBold.fontWeight};
`;
const drillDownStyle = {
  backgroundColor: theme.colors.base.white,
  border: "0px",
};
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid ${(props) => props.theme.colors.base.borderColor};
  padding: 0 16px;
  height: 46px;
`;

const RightComp = styled.div`
  display: inline-flex;
  cursor: pointer;
  border-left: 2px solid ${(props) => props.theme.colors.base.borderColor};
`;

class Header extends React.Component<ILogoutProps> {
  render() {
    const { t } = this.props;
    return (
      <HeaderWrapper>
        <Logo />

        {storageGetItem(appConstants.ADMIN_TOKEN) ? (
          <RightComp>
            <HeaderDrillDown
              id={"marketOrg"}
              value={this.props.loginMarketOrg && this.props.loginMarketOrg.marketOrgName}
              key={"1"}
              style={drillDownStyle}
            />
            <UserProfile
              name={IconName.User}
              translate={t}
              logoutAdmin={this.props.logoutAdmin}
              userProfileSelfSuccess={this.props.userProfileSelfSuccess}
            />
          </RightComp>
        ) : null}
      </HeaderWrapper>
    );
  }
}

export default withTranslation()(Header);
