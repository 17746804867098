/**
 * @description This file is for api hitting using
 * axios for the logout web UI user.
 */
import Gateway from "../gateway/gateway";
import { IErrorResponse } from "../models/iApiError";
import { getUserMgmtEndpoint } from "../utils/apiUtils";

/**
 * @description Function to get user setting info of  webUI user
 */
export const userSetting = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint(`/users/my-setting`));
};

/**
 * @description - Get the browser's accept languages.
 */
export const getBrowserLanguage = async (): Promise<any | IErrorResponse> => {
  return Gateway.getUnAuthorized("/service/locale/getBrowserLanguage");
};

/**
 * @description Function to get user details of webUI user
 */
export const userSettingDetail = async (): Promise<any | IErrorResponse> => {
  return Gateway.get(getUserMgmtEndpoint(`/users/my-context`));
};
