// @ts-ignore
import { appConstants } from "ADMIN_TARGET_BUILD/config";
import { FullPage } from "am-web-ui-shared/elements";
import { gridUtils } from "am-web-ui-shared/components";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "./common/components/layout";
import { storageGetItem } from "./common/utils/storageUtils";
import { protectedRoutes, publicRoutes } from "./config.hilti";
import translate from "./hoc/translate";

interface IState {
  response: any;
}

interface IProps {
  t: any;
  currentUserRole: { code: string };
}

const oAuth = () => {
  return process.env.NODE_ENV === "development" ? true : !!storageGetItem(appConstants.ADMIN_TOKEN);
};

const renderChildren = (props, route) => {
  return oAuth() ? (
    <Layout>
      <route.component {...props} routes={route.routes} />
    </Layout>
  ) : (
    <Redirect to={appConstants.REDIRECT_TO_LOGIN} />
  );
};

const ProtectedRoutes = (route) => (
  <Route exact={route.exact} path={route.path} children={(props) => renderChildren(props, route)} />
);

class Index extends React.Component<IProps, IState> {
  matchUserType = (userType) => this.props.currentUserRole?.code === userType;

  dependencies = {
    access: this.matchUserType,
  };

  resolveDependencies = (dependencyName, params) => {
    return gridUtils.dependencyResolver(this.dependencies, dependencyName)(params);
  };

  shouldDisplayRoute = (route) => {
    let isDisplay = true;
    if (route.dependencies) {
      for (const [key, value] of Object.entries(route.dependencies)) {
        isDisplay = this.resolveDependencies(key, value);
      }
    }
    return isDisplay;
  };

  render() {
    return (
      <FullPage>
        <Switch>
          {publicRoutes &&
            publicRoutes.map((route) => (
              <Route
                exact={route.exact}
                path={route.path}
                children={(props) => (
                  <Layout noHeader={route.noHeader}>
                    <route.component {...props} />
                  </Layout>
                )}
              />
            ))}
          {protectedRoutes &&
            protectedRoutes.map((route, i) => this.shouldDisplayRoute(route) && <ProtectedRoutes key={i} {...route} />)}
        </Switch>
      </FullPage>
    );
  }
}

export default translate(Index) as any;
