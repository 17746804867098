import HiltiToken from "./hiltiToken";

const localStorageTokenKey = "customerTokenResponse";
let cachedCustomerTokenResponse: HiltiToken | null;

const getCustomerTokenResponse = (): HiltiToken | null => {
  if (!cachedCustomerTokenResponse) {
    const tokenJsonString = localStorage.getItem(localStorageTokenKey);
    if (tokenJsonString) {
      cachedCustomerTokenResponse = new HiltiToken(JSON.parse(tokenJsonString));
    }
  }
  return cachedCustomerTokenResponse;
};

export const getCustomerToken = (): string => {
  return getCustomerTokenResponse()?.accessToken;
};

export const getCustomerNumber = (): string => {
  return getCustomerTokenResponse()?.subscriptionInfo?.CustomerOriginID;
};

export const removeCustomerToken = () => {
  localStorage.removeItem(localStorageTokenKey);
  cachedCustomerTokenResponse = null;
};

const setToken = (token: HiltiToken | null) => {
  removeCustomerToken();
  const tokenJsonString = token ? JSON.stringify(token.toJson()) : null;
  if (tokenJsonString) {
    localStorage.setItem(localStorageTokenKey, tokenJsonString);
    cachedCustomerTokenResponse = null;
  }
};

export const setCustomerToken = (token: HiltiToken | null): void => {
  setToken(token);
};
